import PhoneInput, { CountryData } from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

type Props = {
    phoneValue: string
    setFieldValue: (formikField: string, value: string | CountryData | {}) => void
    phoneError?: string
    touched?: boolean
}

function PhoneValidationInput({ phoneValue, setFieldValue, phoneError, touched }: Props) {


    return <div id='#country_code'>
        <PhoneInput
            country={'au'}
            preferredCountries={['au']}
            placeholder='PHONE NUMBER'
            value={phoneValue}
            enableSearch={true}
            autoFormat={false}
            onChange={(value, country) => {
                setFieldValue('countryCode', country)
                setFieldValue('phoneNumber', value)
            }}
            containerClass='!flex !gap-2 !mb-2 !w-full'
            inputClass='!bg-grey-light !border-[1px] !border-grey-base88 !text-black-base !placeholder-grey-base88 !pl-14 !px-3 !py-5 !rounded-lg !mb-1 !w-full !outline-none !focus:border-gold-base !focus:shadow-md !transition-all !duration-300 !font-josefin !text-[16px]'
            buttonClass='!bg-grey-light !pr-2'
            dropdownClass='!border-[1px] !border-grey-base88 !text-black-base !placeholder-grey-base88 !rounded-lg'
            specialLabel=''
        />
        {phoneError && touched && <div className='bg-error-base p-2 text-white border-[1px] border-grey-base88 rounded-lg mb-4 text-[14px]'>{phoneError}</div>}
    </div>
}

export default PhoneValidationInput
