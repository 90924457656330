

import React from 'react'
import { IconBaseProps } from '../../types/types'

interface Props extends IconBaseProps {

}

function CreditCard({ className, onClick }: Props) {

  return (
    <div className={`${onClick ? 'cursor-pointer hover:scale-110 hover:rotate-3 transition-all duration-300 ' : ' '}` + className} onClick={onClick}>
      <svg id="credit_card_black_24dp" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35">
        <path id="Path_766" data-name="Path 766" d="M0,0H35V35H0Z" fill="none" />
        <path id="Path_767" data-name="Path 767" d="M4,15.152H26.8V24.3H4ZM4,6H26.8V9.051H4Z" transform="translate(2.102 2.348)" fill="#d6a156" opacity="0.3" />
        <path id="Path_768" data-name="Path 768" d="M28.009,4H4.89A2.848,2.848,0,0,0,2.014,6.85L2,23.947A2.86,2.86,0,0,0,4.89,26.8H28.009a2.86,2.86,0,0,0,2.89-2.85V6.85A2.86,2.86,0,0,0,28.009,4Zm0,19.947H4.89V15.4H28.009Zm0-14.248H4.89V6.85H28.009Z" transform="translate(1.051 2.102)" fill="#5e257d" />
      </svg>
    </div>
  )
}

export default CreditCard