import { SubscriptionPlan } from '../types/types'
import type { Stripe } from 'stripe'
import subscriptionPlans from '../constants/subscription-plans'

const getCurrentPlanFromSubscription = (planDetails: Stripe.Plan): SubscriptionPlan => {
	const matchedPlan = subscriptionPlans.find((plan) => plan.stripePriceId === planDetails.id)

	if (matchedPlan) return matchedPlan

	return {
		price: 0,
		stripePriceId: '0',
		paypalPlanId: '0',
		subscriptionPeriodText: 'Plan not found!',
		isFreeTrial: false,
		canSubscribeTo: false,
	}
}

export default getCurrentPlanFromSubscription
