import * as Yup from 'yup'
import { PhoneNumberUtil } from 'google-libphonenumber'

const passwordRegExp = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&.^,/'"(){}|;:+=_\-\\[\]<>`])[A-Za-z\d@$!%*#?&.^,/'"(){}|;:+=_\-\\[\]<>`]{8,}$/
const linkRegExp = /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/

type CountryCode = {
	countryCode: string
	dialCode: string
	format: string
	name: string
}

const validatePhoneNumber = (value: string | undefined, countryCode: CountryCode) => {
	let result = false
	try {
		const phoneUtil = PhoneNumberUtil.getInstance()
		result = phoneUtil.isValidNumber(phoneUtil.parse(value, countryCode.countryCode))
	} catch (e) {
		result = false
	}
	return result
}

export const signupSchema = Yup.object().shape({
	firstName: Yup.string().required('Required'),
	lastName: Yup.string().required('Required'),
	birthday: Yup.date().required('Required'),
	email: Yup.string().email('Invalid Email').required('Required'),
	password: Yup.string()
		.required('Required')
		.min(8, 'Password must be at least 8 characters')
		.matches(passwordRegExp, 'Password must contain 1 number and one special character'),
	confirmPassword: Yup.string().when('password', {
		is: (val: string) => (val && val.length > 0 ? true : false),
		then: Yup.string().oneOf([Yup.ref('password')], 'Passwords do not match'),
	}),
})

export const editAccountSchema = Yup.object().shape({
	firstName: Yup.string().required('Required'),
	lastName: Yup.string().required('Required'),
	birthday: Yup.date().required('Required'),
	email: Yup.string().email('Invalid Email').required('Required'),
	userAddress: Yup.object()
		.shape({
			fullAddress: Yup.string(),
			streetName: Yup.string(),
			streetNumber: Yup.string(),
			googlePlaceId: Yup.string(),
			lng: Yup.number(),
			lat: Yup.number(),
			state: Yup.string(),
			country: Yup.string().required('Country is required'),
			city: Yup.string(),
			suburb: Yup.string(),
			postcode: Yup.string(),
		})
		.required(),
})

export const profileSetupSchema = Yup.object().shape({
	instagram: Yup.string(),
	facebook: Yup.string(),
	tiktok: Yup.string(),
})

export type LoginFormikProps = {
	phoneNumber: string
	countryCode: {
		countryCode: string
		dialCode: string
		format: string
		name: string
	}
}

export const loginMobileSchema = Yup.object().shape({
	countryCode: Yup.object().shape({
		countryCode: Yup.string(),
		dialCode: Yup.string(),
		format: Yup.string(),
		name: Yup.string(),
	}),
	phoneNumber: Yup.string()
		.required('Required')
		.test({
			name: 'validate phone number',
			exclusive: false,
			params: {},
			message: 'Invalid Phone Number',
			test: function (value) {
				// You can access the price field with `this.parent`.
				return validatePhoneNumber(value, this.parent.countryCode)
			},
		}),
})

export const loginEmailSchema = Yup.object().shape({
	email: Yup.string().email('Invalid Email').required('Required'),
	password: Yup.string()
		.required('Required')
		.min(8, 'Password must be at least 8 characters')
		.matches(passwordRegExp, 'Password must contain 1 number and one special character'),
})

export const recoverCodeEmail = Yup.object().shape({
	email: Yup.string().email('Invalid Email').required('Required'),
})

export const changePasswordSchema = Yup.object().shape({
	password: Yup.string()
		.required('Required')
		.min(8, 'Password must be at least 8 characters')
		.matches(passwordRegExp, 'Password must contain 1 number and one special character'),
	confirmPassword: Yup.string().when('password', {
		is: (val: string) => (val && val.length > 0 ? true : false),
		then: Yup.string().oneOf([Yup.ref('password')], 'Passwords do not match'),
	}),
})
