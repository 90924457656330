import { useState } from 'react'
import SubscribePaymentMain from '../assets/images/SubscribePaymentMain.jpg'
import SubscribePlan from '../components/subscribe-plan'
import SubscribePayment from '../components/subscribe-payment'
import { SubscribePage, SubscriptionPlan } from '../types/types'

function Subscribe() {
	const [page, setPage] = useState<SubscribePage>('PLAN')
	const [plan, setPlan] = useState<SubscriptionPlan>()

	const renderPage = (): JSX.Element => {
		switch (page) {
			case 'PLAN':
				return <SubscribePlan setPage={setPage} setPlan={setPlan} selectedPlan={plan} />
			case 'PAYMENT':
				return <SubscribePayment setPage={setPage} plan={plan} />
		}
	}

	return (
		<div className='flex flex-col min-h-screen'>
			<div className='px-4 py-8 md:h-auto flex flex-col'>{renderPage()}</div>
			<div className='grow flex items-end'>{page === 'PAYMENT' && <img src={SubscribePaymentMain} className='' alt='' />}</div>
		</div>
	)
}

export default Subscribe
