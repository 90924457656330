import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Arrow from '../assets/icons/Arrow'
import Help from '../assets/icons/Help'
import AuthService from '../services/auth'
import asyncTimeout from '../utils/asyncTimeout'
import errorPopupParser from '../utils/error-popup-parser'
import useGlobalState from '../utils/use-global-state'
import Button from './core/button'
import ModalWrapper from './core/modal-wrapper'
import TextInput from './core/text-input'

type Props = {
    isOpen: boolean
    setIsOpen: (isOpen: boolean) => void
}

function DeleteAccountModal({ isOpen, setIsOpen}: Props) {

    const { dispatch, state } = useGlobalState()
    const auth = new AuthService()
    const navigate = useNavigate()

    const [confirmationText, setConfirmationText] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const deleteUserAccount = async () => {
        if (confirmationText !== 'Delete Account' || !state.user) return

        setIsLoading(true)

        try {
            const deleteResult = await Promise.all([auth.deleteOne(state.user.id), asyncTimeout(3000)])

            localStorage.removeItem('WHMToken')
            dispatch({ type: 'setUser', data: undefined })
            navigate('/login')

        } catch (error) {
            errorPopupParser(error, dispatch)
        } finally {
            setIsLoading(false)
        }

    }

    return (
        <ModalWrapper isOpen={isOpen} setIsOpen={setIsOpen}>
            <div className='absolute bottom-0 bg-white ' onClick={(e) => e.stopPropagation()}>
                <div className='flex items-center p-3 border-b border-grey-base88 font-bold'>
                    <Arrow onClick={() => setIsOpen(false)}/>
                    <div className='mx-auto text-[20px] text-black-base'>Delete Account</div>
                </div>

                <div className='p-3 text-[18px] text-black-base opacity-80 leading-[20px]' >
                    By deleting your account you will permanently remove your account from WHM.
                </div>

                <div className='bg-[#5E257D1A] p-3 rounded-lg mx-3 mb-4'>
                    <div className='text-black-base text-[20px] font-semibold flex justify-between mb-2'>
                        What About My Subscription?
                        <Help />
                    </div>
                    <div className='text-black-light opacity-80 text-[18px] mb-2 leading-6'>If you choose to delete your account, your WHM Paid Subscription will also be terminated once actioned.
                    </div>
                    <div className='text-black-light opacity-80 text-[18px] mb-2 leading-6'>
                    Note that users who delete their account during a paid billing cycle will not be refunded their remaining subscription period.
                    </div>
                </div>

                <div className='text-black-base text-[20px] font-semibold flex justify-between mx-3 mb-2'>Confirm Deletion</div>

                <div className='px-3 text-[18px] text-black-base opacity-80 leading-[20px] mb-2' >
                    To confirm your account deletion enter inside the field below <span className='text-error-base'>“Delete Account”</span> to finalise deletion.
                </div>

                <div className='mx-3 mb-4'>
                    <TextInput placeholder='Enter "Delete Account"' value={confirmationText} onChange={(value) => setConfirmationText(value)} />
                </div>

                <div className='mx-3 mb-8'>
                    <Button text='Delete Account' isLoading={isLoading} onClick={deleteUserAccount} className='text-white bg-error-base hover:bg-error-base border-white hover:border-white hover:text-white'/>
                </div>

            </div>
        </ModalWrapper>
    )
}

export default DeleteAccountModal
