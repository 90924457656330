import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Arrow from '../assets/icons/Arrow'
import Logo from '../assets/images/WHMLogo.jpg'
import Button from '../components/core/button'
import PaymentMethodCard from '../components/payment-method-card'
import StripeService from '../services/stripe'
import errorPopupParser from '../utils/error-popup-parser'
import useGlobalState from '../utils/use-global-state'
import { PaymentMethod } from '@stripe/stripe-js'
import asyncTimeout from '../utils/asyncTimeout'
import AddPaymentMethodModal from '../components/add-payment-method-modal'
import PaymentMethodOptionsModal from '../components/payment-method-options-modal'

function PaymentMethods() {
	const { state, dispatch } = useGlobalState()
	const { user } = state
	const stripe = new StripeService()
	const navigate = useNavigate()

	const [isLoading, setIsLoading] = useState(true)
	const [defaultPaymentMethod, setDefaultPaymentMethod] = useState<PaymentMethod>()
	const [otherPaymentMethods, setOtherPaymentMethods] = useState<PaymentMethod[]>([])

	const [isAddPaymentMethodOpen, setIsAddPaymentMethodOpen] = useState(false)
	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethod | undefined>(undefined)

	const getPaymentMethods = async () => {
		try {
			const [paymentMethodResult] = await Promise.all([stripe.getPaymentMethods(), asyncTimeout(500)])
			setDefaultPaymentMethod(paymentMethodResult.defaultPaymentMethod)
			setOtherPaymentMethods(paymentMethodResult.paymentMethodList)
		} catch (error) {
			errorPopupParser(error, dispatch)
		} finally {
			setIsLoading(false)
		}
	}

	const renderDefaultMethod = () => {
		if (isLoading)
			return (
				<div className='flex items-center gap-4 border-2 p-3 mb-4 rounded-lg bg-grey-light h-[66px] border-grey-light select-none animate-pulse' />
			)

		if (!defaultPaymentMethod) return <div className='mb-4'>No payment methods found</div>

		return <PaymentMethodCard isDefault paymentMethod={defaultPaymentMethod} />
	}

	const renderOtherPaymentMethods = () => {
		if (isLoading)
			return Array(3)
				.fill('0')
				.map((item, index) => {
					return (
						<div
							className='flex items-center gap-4 border-2 p-3 mb-4 rounded-lg bg-grey-light h-[66px] border-grey-light select-none animate-pulse'
							key={index}
						/>
					)
				})

		if (!defaultPaymentMethod) return <div className='mb-4'>No payment methods found</div>

		return otherPaymentMethods.map((paymentMethod, index) => {
			return <PaymentMethodCard key={index} paymentMethod={paymentMethod} setSelectedPaymentMethod={setSelectedPaymentMethod} />
		})
	}

	useEffect(() => {
		getPaymentMethods()
	}, [])

	const renderPaypal = () => {
		return (
			<div>
				<div className='text-[30px] font-bold text-black-base leading-[24px] mb-2'>Payment Methods</div>
				<p className='text-[18px] leading-[20px] opacity-80 mb-8'>Login to your paypal account to update your payment method.</p>
			</div>
		)
	}

	const renderStripe = () => {
		return (
			<>
				<AddPaymentMethodModal isOpen={isAddPaymentMethodOpen} setIsOpen={setIsAddPaymentMethodOpen} getPaymentMethods={getPaymentMethods} />

				<PaymentMethodOptionsModal
					paymentMethod={selectedPaymentMethod}
					closeModal={() => setSelectedPaymentMethod(undefined)}
					getPaymentMethods={getPaymentMethods}
				/>

				<div className='text-[30px] font-bold text-black-base leading-[24px] mb-2'>Payment Methods</div>
				<p className='text-[18px] leading-[20px] opacity-80 mb-8'>Add, update or remove payment methods. Select the method to make changes.</p>

				<div className='text-[18px] font-bold leading-[20px] opacity-80 mb-2'>Default Payment Method:</div>

				{renderDefaultMethod()}

				<div className='text-[18px] font-bold leading-[20px] opacity-80 mb-2'>Other Payment Methods:</div>

				{renderOtherPaymentMethods()}

				<Button text='Add Method' onClick={() => setIsAddPaymentMethodOpen(true)} isLoading={isLoading} />
			</>
		)
	}

	return (
		<div className='px-4 py-8 h-screen md:h-auto flex flex-col'>
			<div className='flex items-center justify-center relative mb-12 w-full'>
				<Arrow className='absolute left-0' onClick={() => navigate('/login')} />
				<img src={Logo} className='h-5' alt='WHM Logo' />
			</div>

			{user?.userSubscriptionInformation?.type === 'PAYPAL' ? renderPaypal() : renderStripe()}
		</div>
	)
}

export default PaymentMethods
