import { LoginPageNames } from '../pages/login'
import Arrow from '../assets/icons/Arrow'
import Logo from '../assets/images/WHMLogo.jpg'
import PhoneValidationInput from './core/phone-validation-input'
import Button from './core/button'
import { FormikProps } from 'formik'
import { LoginFormikProps } from '../utils/validation-schemas'

type Props = {
	setPage: (pageName: LoginPageNames) => void
	loginFormik: FormikProps<LoginFormikProps>
}
const LoginMobile = ({ setPage, loginFormik }: Props) => {
	const { values, errors, touched, setFieldValue, handleSubmit } = loginFormik

	return (
		<div className='px-4 py-8 h-screen md:h-auto flex flex-col select-none'>
			<div className='flex items-center justify-center relative mb-12 w-full'>
				<Arrow className='absolute left-0' onClick={() => setPage('REGISTER')} />
				<img src={Logo} className='h-5' alt='WHM Logo' />
			</div>

			<div className='text-[30px] font-bold text-black-base mb-2 leading-[35px]'>Welcome Back!</div>
			<div className='text-[16px] text-black-light leading-5 mb-4'>
				Sign back into your existing WHM account with your mobile phone number.
			</div>
			<div className='text-[16px] text-black-light leading-5 mb-2'>Enter your mobile phone number below to sign in!</div>

			<PhoneValidationInput
				phoneValue={values.phoneNumber}
				setFieldValue={setFieldValue}
				phoneError={errors.phoneNumber}
				touched={touched.phoneNumber}
			/>

			<Button text={`Next`} type='submit' onClick={handleSubmit} className='mb-2 mt-4' />

			<p className='text-[12px] text-black-light leading-5 mb-8'>
				By entering in your phone number you agree to receive a text message from Womens Health Movement
			</p>

			<p className='text-[16px] text-black-base leading-5 mb-2 font-bold'>Having trouble with your mobile number?</p>
			<p className='text-[16px] text-black-base leading-5 mb-2'>
				If you have changed your mobile number or are having trouble signing in, you can also use your email and password to sign in to your
				account.
			</p>
			<Button
				text={`Sign In With Email & Password`}
				onClick={() => setPage('EMAIL_LOGIN')}
				className='mb-12 mt-4 text-[14px] bg-white text-gold-base hover:text-gold-base hover:bg-white '
			/>

			<p className='text-[16px] text-black-light leading-5 mb-2 '>
				Don't have an account?{' '}
				<span className='text-purple-base hover:cursor-pointer' onClick={() => setPage('REGISTER')}>
					Create one here!
				</span>
			</p>
		</div>
	)
}

export default LoginMobile
