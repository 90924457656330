import { useEffect, useState } from 'react'
import Arrow from '../assets/icons/Arrow'
import { SubscribePage, SubscriptionPlan } from '../types/types'
import Logo from '../assets/images/WHMLogo.jpg'
import Button from './core/button'
import PlanCard from './plan-card'
import PaymentField from './payment-field'
import { PaymentMethod } from '@stripe/stripe-js'
import StripeService from '../services/stripe'
import errorPopupParser from '../utils/error-popup-parser'
import useGlobalState from '../utils/use-global-state'
import { useNavigate } from 'react-router-dom'
import refreshUserData from '../utils/refresh-user-data'
import PaypalButton from './paypal-button'
import { OnApproveData } from '@paypal/paypal-js'
import PaypalService from '../services/paypal'

type Props = {
	setPage: (page: SubscribePage) => void
	plan: SubscriptionPlan | undefined
}

function SubscribePayment({ setPage, plan }: Props) {
	const [paymentMethodId, setPaymentMethodId] = useState<PaymentMethod>()
	const [isLoading, setIsLoading] = useState(false)

	const { state, dispatch } = useGlobalState()
	const { user } = state

	const stripe = new StripeService()
	const paypal = new PaypalService()
	const navigate = useNavigate()

	function isPlanSet(plan: SubscriptionPlan | undefined): plan is SubscriptionPlan {
		if (!plan) return false
		return 'price' in plan && 'stripePriceId' in plan && 'paypalPlanId' in plan && 'subscriptionPeriodText' in plan
	}

	useEffect(() => {
		if (!isPlanSet(plan)) {
			setPage('PLAN')
			return
		}
	}, [])

	const addPaymentMethod = async () => {
		if (!paymentMethodId || !plan) return

		try {
			const result = await stripe.addPaymentMethod(paymentMethodId.id)

			return result
		} catch (error) {
			errorPopupParser(error, dispatch)
		}
	}

	const createSubscription = async () => {
		if (!plan) return

		try {
			const result = await stripe.createSubscription(plan.stripePriceId, user!.id)

			return result
		} catch (error) {
			errorPopupParser(error, dispatch)
		}
	}

	const subscribeUser = async () => {
		setIsLoading(true)

		try {
			const paymentMethodAttachResult = await addPaymentMethod()

			if (paymentMethodAttachResult !== 201) return

			await createSubscription()

			dispatch({
				type: 'setSnack',
				data: {
					isOpen: true,
					message: 'Subscription successful! You will be redirected shortly.',
					severity: 'success',
				},
			})

			setTimeout(() => {
				refreshUserData(dispatch)
			}, 3000)

			setTimeout(() => {
				navigate('/')
			}, 2000)
		} catch (error) {
			errorPopupParser(error, dispatch)
		} finally {
			setIsLoading(false)
		}
	}

	const handlePaypalApproved = async (paypalApproved: OnApproveData) => {
		if (!paypalApproved.subscriptionID) return
		setIsLoading(true)

		try {
			await paypal.createSubscription(paypalApproved.subscriptionID, user!.id)

			dispatch({
				type: 'setSnack',
				data: {
					isOpen: true,
					message: 'Subscription successful! You will be redirected shortly',
					severity: 'success',
				},
			})

			setTimeout(() => {
				refreshUserData(dispatch)
				navigate('/')
			}, 4000)
		} catch (error) {
		} finally {
			setIsLoading(false)
		}
	}

	return (
		<>
			<div className='flex items-center justify-center relative mb-12 w-full'>
				<Arrow className='absolute left-0' onClick={() => setPage('PLAN')} />
				<img src={Logo} className='h-5' alt='WHM Logo' />
			</div>

			<div className='text-[28px] mb-2 font-bold text-black-base leading-9'>Nearly there! </div>
			<div className='text-[16px] mb-2 text-black-light leading-5'>Let's finish setting your subscription up with your payment details.</div>

			<div className='font-bold mb-2'>Your Plan</div>

			{plan && <PlanCard plan={plan} isSelected={true} setPlan={() => null} isAllPlansView={true} />}

			<div className=' text-[11px] text-black-light mt-2 mb-12 text-center'>
				This amount will automatically be direct debited from your account at the end of the free trial period.
			</div>

			<div className='font-bold mb-2'>Payment Details</div>

			<PaymentField setPaymentMethodId={setPaymentMethodId} />

			<Button text='Subscribe!' disabled={!paymentMethodId} onClick={subscribeUser} className='mb-4 py-1' isLoading={isLoading} />
		</>
	)
}

export default SubscribePayment
