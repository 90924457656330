import { useEffect, useState } from 'react'
import { CardNumberElement, CardCvcElement, CardExpiryElement, useStripe, useElements } from '@stripe/react-stripe-js'
import TextInput from './core/text-input'
import { CircularProgress } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import { Fade } from '@mui/material'
import { PaymentMethod } from '@stripe/stripe-js'

const CARD_ELEMENT_OPTIONS = {
	style: {
		base: {
			backgroundColor: '#FFFFFF',
			fontFamily: '"Josefin", sans-serif',
			fontSmoothing: 'antialiased',
			fontWeight: '400',
			fontSize: '18px',
			lineHeight: '26px',

			'::placeholder': {
				color: '#ADADAD88',
			},
		},
		invalid: {
			color: '#ED0000',
			iconColor: '#ED0000',
		},
	},
}

type PaymentResults = 'SUCCESS' | 'FAILED' | 'IN PROGRESS'

type Props = {
	setPaymentMethodId: (pmId: PaymentMethod | undefined) => void
}

function PaymentField({ setPaymentMethodId }: Props) {
	const [cardName, setCardName] = useState('')
	const [isLoading, setIsLoading] = useState(false)

	const [isNumberComplete, setIsNumberComplete] = useState(false)
	const [isCVCComplete, setIsCVCComplete] = useState(false)
	const [isExpiryComplete, setIsExpiryComplete] = useState(false)

	const [paymentResult, setPaymentResult] = useState<PaymentResults>('IN PROGRESS')

	const elements = useElements()
	const stripe = useStripe()

	const createPaymentMethod = async () => {
		if (!stripe || !elements || !cardName) return

		const cardElement = elements.getElement(CardNumberElement)

		if (!cardElement) return

		setIsLoading(true)
		setPaymentResult('IN PROGRESS')

		try {
			const result = await stripe.createPaymentMethod({
				type: 'card',
				card: cardElement,
				billing_details: {
					name: cardName,
				},
			})

			setPaymentMethodId(result.paymentMethod)
		} catch (error) {
			setPaymentResult('FAILED')
			setPaymentMethodId(undefined)
		} finally {
			setPaymentResult('SUCCESS')
			setTimeout(() => {
				setIsLoading(false)
			}, 1200)
		}
	}

	const renderLoadingIndicators = (): JSX.Element => {
		if (paymentResult === 'SUCCESS')
			return (
				<div className='flex items-center gap-2'>
					<CheckIcon sx={{ color: 'limegreen' }} />
					Successfully processed payment method
				</div>
			)
		else if (paymentResult === 'FAILED')
			return (
				<div className='flex items-center gap-2'>
					<CloseIcon sx={{ color: '#E33B45' }} />
					Invalid payment details
				</div>
			)
		else return <CircularProgress size={'24px'} thickness={4.5} sx={{ color: '#5E257D' }} />
	}

	useEffect(() => {
		if (cardName && isNumberComplete && isCVCComplete && isExpiryComplete) createPaymentMethod()
		else setPaymentMethodId(undefined)
	}, [cardName, isNumberComplete, isCVCComplete, isExpiryComplete])

	return (
		<div>
			<TextInput placeholder='NAME ON CARD' value={cardName} onChange={(text) => setCardName(text)} />

			<CardNumberElement
				className='input-primary font-josefin'
				options={CARD_ELEMENT_OPTIONS}
				onChange={(e) => setIsNumberComplete(e.complete)}
			/>
			<div className='flex gap-2 w-full mb-4'>
				<CardCvcElement
					className='input-primary w-[40%] font-josefin'
					options={CARD_ELEMENT_OPTIONS}
					onChange={(e) => setIsCVCComplete(e.complete)}
				/>

				<CardExpiryElement
					className='input-primary w-[40%] font-josefin'
					options={CARD_ELEMENT_OPTIONS}
					onChange={(e) => setIsExpiryComplete(e.complete)}
				/>
			</div>

			<Fade in={isLoading} timeout={600}>
				<div className='mb-4'>{renderLoadingIndicators()} </div>
			</Fade>
		</div>
	)
}

export default PaymentField
