import React, { ChangeEvent, useState } from 'react'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers'
import { TextField } from '@mui/material'
import moment from 'moment'

type Props = {
    value: Date | string
    onChange: (e: Date | string) => void
    error?: string
    touched?: boolean
    className?: string
    placeholder?: string
}

function DateValidationInput({ value, onChange, error, touched, className, placeholder }: Props) {

    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false)

    const date = moment()

    const formatDateValue = (): string => {
        // if (typeof value === 'string') return value
        if (value === '') return value
        return moment(value).format('DD-MM-YYYY')
    }

    return <LocalizationProvider dateAdapter={AdapterMoment}>
        <div className='relative w-full'>
            <MobileDatePicker 
            value={value}
            onChange={(newValue) => newValue ? onChange(new Date(newValue)) : onChange('')}
            renderInput={(params) => <TextField {...params} sx={{ display: 'none' }} />}
            onClose={() => setIsDatePickerOpen(false)}
            open={isDatePickerOpen}
            className='font-josefin'
            disableHighlightToday
            reduceAnimations
            DialogProps={{ sx: {
                fontFamily: 'Josefin'
            }}}
            />

            <input className={`input-primary ` + className} placeholder={placeholder} value={formatDateValue()} onChange={() => null} onClick={() => setIsDatePickerOpen(true)} readOnly/>
            
            {error && touched && <div className='bg-error-base p-2 text-white border-[1px] border-grey-base88 rounded-lg mb-4 text-[14px]'>{error}</div>}

        </div>

    </LocalizationProvider>
    
}

export default DateValidationInput