import React, { useState } from 'react'
import PlacesAutocomplete, { geocodeByPlaceId } from 'react-places-autocomplete'
import { CircularProgress } from '@mui/material'
import parseGoogleAddress, { ParsedAddressComponents } from '../../utils/parse-google-address'

type AddressError = {
	city?: string
	country?: string
	fullAddress?: string
	lat?: string
	lng?: string
	googlePlaceId?: string
	state?: string
	streetName?: string
	streetNumber?: string
	suburb?: string
	postcode?: string
}

type AddressTouched = {
	city?: boolean
	country?: boolean
	fullAddress?: boolean
	lat?: boolean
	lng?: boolean
	googlePlaceId?: boolean
	state?: boolean
	streetName?: boolean
	streetNumber?: boolean
	suburb?: boolean
	postcode?: boolean
}

type Props = {
	errors?: AddressError | undefined
	touched?: AddressTouched | undefined
	setAddress: (address: ParsedAddressComponents) => void
	initialQuery?: string
}

function AutocompleteInput({ errors, touched, setAddress, initialQuery }: Props) {
	const [query, setQuery] = useState(initialQuery ?? '')

	const handleSelect = async (placeId: string) => {
		try {
			const addressObjResult = await geocodeByPlaceId(placeId)

			const address = parseGoogleAddress(addressObjResult[0])
			setQuery(address.fullAddress ?? '')
			setAddress(address)
		} catch (error) {}
	}

	const getFirstError = (): string => {
		if (!errors) return 'Invalid address, please ensure the address you have chosen is fully formatted'

		return Object.values(errors)[0]
	}

	return (
		<>
			<div className='text-grey-base text-sm'>*Your address must contain at least a country.</div>
			<PlacesAutocomplete value={query} onChange={setQuery} onSelect={(address, placeId) => handleSelect(placeId)} debounce={400}>
				{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
					<div>
						<input
							{...getInputProps({
								placeholder: 'Location',
								className: 'input-primary',
							})}
						/>
						<div className='mb-1'>
							{loading && <CircularProgress size={'24px'} thickness={4.5} sx={{ color: '#5E257D' }} />}
							{suggestions.map((suggestion) => {
								return (
									<div className='input-primary p-2' {...getSuggestionItemProps(suggestion)}>
										<span>{suggestion.description}</span>
									</div>
								)
							})}
						</div>
					</div>
				)}
			</PlacesAutocomplete>
			{errors && touched && (
				<div className='bg-error-base p-2 text-white border-[1px] border-grey-base88 rounded-lg mb-4 text-[14px]'>{getFirstError()}</div>
			)}
		</>
	)
}

export default AutocompleteInput
