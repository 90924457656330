

import React from 'react'
import { IconBaseProps } from '../../types/types'

interface Props extends IconBaseProps {
    alignment?: 'right' | 'left' | 'top' | 'bottom'
}

function AboutUs({ className, onClick, alignment }: Props) {

    const  getRotationValue = () => {
        switch(alignment){
          case 'right' : return 'rotate(180deg)'
        }
      }

  return (
    <div className={`${onClick ? 'cursor-pointer hover:scale-110 hover:rotate-3 transition-all duration-300 ' : ' '}` + className} onClick={onClick} style={{ transform: getRotationValue() }}>
          <svg id='arrow_back_black_24dp' xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'>
              <path id='Path_41' data-name='Path 41' d='M0,0H30V30H0Z' fill='none' />
              <path id='Path_42' data-name='Path 42' d='M24,12.75H8.788l6.987-6.988L14,4,4,14,14,24l1.763-1.763L8.788,15.25H24Z' transform='translate(1 1)' fill='#5e257d' />
          </svg>
    </div>
  )
}

export default AboutUs