import axios from 'axios'
import { PresignedUpload, UploadFolder } from '../types/types'
import Instance from '../utils/axios'

const networkErrorMessage = 'There was an error with your connection, please try again'

class FileService {
	getSingleUploadURL = async (fileName: string, folder: UploadFolder): Promise<PresignedUpload> => {
		try {
			const result = await Instance.get(`files/upload/images?fileName=${fileName}&folder=${folder}`)

			return result.data
		} catch (error) {
			if (error && axios.isAxiosError(error)) {
				if (error?.code === 'ERR_NETWORK' || error?.code === 'ECONNABORTED') throw Error(networkErrorMessage)
			}

			throw Error('Invalid upload path')
		}
	}

	getSingleDownloadURL = async (fileName: string): Promise<string> => {
		try {
			const result = await Instance.get(`files/download/resize?fileName=${fileName}&width=${140}&height=${140}`)

			return result.data.url
		} catch (error) {
			if (error && axios.isAxiosError(error)) {
				if (error?.code === 'ERR_NETWORK' || error?.code === 'ECONNABORTED') throw Error(networkErrorMessage)
			}

			throw Error('Invalid upload path')
		}
	}

	uploadWithPresignedURL = async (presignedURL: string, file: File): Promise<string> => {
		const buffer = await file.arrayBuffer()

		try {
			const myHeaders = new Headers({ 'Content-Type': file.type })
			const result = await fetch(presignedURL, {
				method: 'PUT',
				headers: myHeaders,
				body: buffer,
			})

			return result.url
		} catch (error) {
			if (error && axios.isAxiosError(error)) {
				if (error?.code === 'ERR_NETWORK' || error?.code === 'ECONNABORTED') throw Error(networkErrorMessage)
			}

			throw Error('File upload failed')
		}
	}
}

export default FileService
