import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Arrow from '../assets/icons/Arrow'
import Logo from '../assets/images/WHMLogo.jpg'
import Button from '../components/core/button'
import PhoneValidationInput from '../components/core/phone-validation-input'
import VerificationCode from '../components/verficiation-code'
import AuthService from '../services/auth'
import useGlobalState from '../utils/use-global-state'
import { useFormik } from 'formik'
import { loginMobileSchema } from '../utils/validation-schemas'
import parseMobileNumber from '../utils/parse-mobile-number'
import asyncTimeout from '../utils/asyncTimeout'
import errorPopupParser from '../utils/error-popup-parser'
import ValidationInput from '../components/core/validation-input'
import ChangePassword from '../components/change-password'
import VerificationService from '../services/verification'
import { CredentialDetailsEmail, CredentialDetailsMobile } from '../types/types'

type UpdatePasswordPages = 'VERIFY_CODE' | 'NEW_PASSWORD'

function UpdatePassword() {
	const navigate = useNavigate()
	const auth = new AuthService()
	const verify = new VerificationService()
	const { state, dispatch } = useGlobalState()
	const { user } = state

	const [page, setPage] = useState<UpdatePasswordPages>('VERIFY_CODE')
	const [code, setCode] = useState('')
	const [isLoading, setIsLoading] = useState(false)
	const [isCodeSent, setIsCodeSent] = useState(false)

	const hasSentCode = useRef(false)

	const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
		initialValues: {
			phoneNumber: '',
			countryCode: {
				countryCode: 'au',
				dialCode: '61',
				format: '+.. (..) .... ....',
				name: 'Australia',
			},
		},
		onSubmit: (values) => {
			sendVerificationCode(parseMobileNumber(values.phoneNumber, values.countryCode.countryCode), values.countryCode.dialCode)
		},
		validationSchema: loginMobileSchema,
	})

	const renderSwitch = () => {
		if (!user) return

		switch (page) {
			case 'VERIFY_CODE':
				return (
					<VerificationCode
						source={user?.userMobile.countryCode + user?.userMobile.number}
						verifyCode={verifyCode}
						isLoading={isLoading}
						resendCode={() => sendVerificationCode(user!.userMobile.number, user!.userMobile.countryCode)}
						hideTips
					/>
				)
			case 'NEW_PASSWORD':
				return <ChangePassword onChangeComplete={updateUserPassword} onClickBack={() => navigate('/')} />
		}
	}

	const updateUserPassword = async (password: string, confirmPassword: string) => {
		if (!user) return

		setIsLoading(true)

		const credentialDetails: CredentialDetailsEmail | CredentialDetailsMobile = {
			number: user?.userMobile.number,
			countryCode: user?.userMobile.countryCode,
		}

		try {
			await Promise.all([auth.updatePassword(credentialDetails, code, password, confirmPassword), asyncTimeout(900)])

			dispatch({
				type: 'setSnack',
				data: {
					isOpen: true,
					message: 'Password successfully updated!',
					severity: 'success',
				},
			})

			setTimeout(() => {
				navigate('/')
			}, 1500)
		} catch (error) {
			errorPopupParser(error, dispatch)
		} finally {
			setIsLoading(false)
		}
	}

	const verifyCode = async (code: string) => {
		const credentialDetails: CredentialDetailsMobile | CredentialDetailsEmail = {
			number: user!.userMobile.number,
			countryCode: user!.userMobile.countryCode,
		}

		try {
			const [checkCodeResult] = await Promise.all([verify.checkCode(credentialDetails, code, 'change_password'), asyncTimeout(900)])

			setCode(code)
			setPage('NEW_PASSWORD')
		} catch (error) {
			errorPopupParser(error, dispatch)
		} finally {
			setIsLoading(false)
		}
	}

	const sendVerificationCode = async (number: string, countryCode: string) => {
		setIsLoading(true)

		const credentialDetails: CredentialDetailsMobile | CredentialDetailsEmail = {
			number,
			countryCode,
		}

		try {
			const result = await verify.sendVerificationCode(credentialDetails)
		} catch (error) {
		} finally {
			setIsLoading(false)
		}
	}

	useEffect(() => {
		if (hasSentCode.current) return

		if (user) sendVerificationCode(user.userMobile.number, user.userMobile.countryCode)

		if (!hasSentCode.current) hasSentCode.current = true
	}, [user])

	return <div>{renderSwitch()}</div>
}

export default UpdatePassword
