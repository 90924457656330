import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Arrow from '../assets/icons/Arrow'
import VerificationCode from '../components/verficiation-code'
import Logo from '../assets/images/WHMLogo.jpg'
import Button from '../components/core/button'
import { useFormik } from 'formik'
import { recoverCodeEmail } from '../utils/validation-schemas'
import ValidationInput from '../components/core/validation-input'
import { CredentialDetailsEmail, CredentialDetailsMobile } from '../types/types'
import VerificationService from '../services/verification'
import errorPopupParser from '../utils/error-popup-parser'
import useGlobalState from '../utils/use-global-state'
import ChangePassword from '../components/change-password'
import AuthService from '../services/auth'
import asyncTimeout from '../utils/asyncTimeout'

type ForgotPassword = 'ENTER_EMAIL' | 'ENTER_CODE' | 'NEW_PASSWORD'

function ForgotPassword() {
	const navigate = useNavigate()
	const verify = new VerificationService()
	const auth = new AuthService()

	const { dispatch } = useGlobalState()

	const [page, setPage] = useState('ENTER_EMAIL')
	const [canResendCode, setCanResendCode] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [code, setCode] = useState('')

	const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
		initialValues: {
			email: '',
		},
		onSubmit: (values) => {
			sendVerificationCode()
		},
		validationSchema: recoverCodeEmail,
	})

	const renderEmailInput = () => {
		return (
			<div className='px-4 py-8 h-screen md:h-auto flex flex-col'>
				<div className='flex items-center justify-center relative mb-8 w-full'>
					<Arrow className='absolute left-0' onClick={() => navigate('/login')} />

					<img src={Logo} className='h-5' alt='WHM Logo' />
				</div>
				<div className='text-[30px] font-bold text-black-base mb-2 leading-[35px]'>Send Verification Code</div>
				<div className='text-[18px] text-black-light leading-5 mb-6'>Enter the email for your WHM account.</div>

				<ValidationInput
					placeholder='EMAIL'
					className='mb-2'
					error={errors.email}
					touched={touched.email}
					value={values.email}
					onChange={(e) => setFieldValue('email', e.target.value)}
				/>

				<div className='grow flex items-end'>
					<Button text='Next' onClick={handleSubmit} isLoading={isLoading} />
				</div>
			</div>
		)
	}

	const renderVerificationInput = () => {
		return (
			<>
				<VerificationCode
					hideTips
					source={values.email}
					verifyCode={verifyCode}
					isLoading={isLoading}
					backHandler={() => setPage('ENTER_EMAIL')}
					resendCode={() => sendVerificationCode()}
				/>
			</>
		)
	}

	const sendVerificationCode = async () => {
		setIsLoading(true)
		setCanResendCode(false)

		const credentialDetails: CredentialDetailsEmail | CredentialDetailsMobile = {
			email: values.email,
		}

		try {
			const result = await verify.sendVerificationCode(credentialDetails)

			setPage('ENTER_CODE')
		} catch (error) {
		} finally {
			setIsLoading(false)
			setTimeout(() => {
				setCanResendCode(true)
			}, 10000)
		}
	}

	const verifyCode = async (code: string) => {
		setIsLoading(true)

		const credentialDetails: CredentialDetailsEmail | CredentialDetailsMobile = {
			email: values.email,
		}

		try {
			await verify.checkCode(credentialDetails, code, 'new_subscription')
			setCode(code)
			setPage('NEW_PASSWORD')
		} catch (error) {
			errorPopupParser(error, dispatch)
		} finally {
			setIsLoading(false)
		}
	}

	const updateUserPassword = async (password: string, confirmPassword: string) => {
		setIsLoading(true)
		const credentialDetails: CredentialDetailsEmail | CredentialDetailsMobile = {
			email: values.email,
		}

		try {
			await Promise.all([auth.updatePassword(credentialDetails, code, password, confirmPassword), asyncTimeout(900)])

			dispatch({
				type: 'setSnack',
				data: {
					isOpen: true,
					message: 'Password successfully reset!',
					severity: 'success',
				},
			})

			setTimeout(() => {
				navigate('/')
			}, 1500)
		} catch (error) {
			errorPopupParser(error, dispatch)
		} finally {
			setIsLoading(false)
		}
	}

	const renderSwitch = () => {
		switch (page) {
			case 'ENTER_EMAIL':
				return renderEmailInput()
			case 'ENTER_CODE':
				return renderVerificationInput()
			case 'NEW_PASSWORD':
				return <ChangePassword onChangeComplete={updateUserPassword} onClickBack={() => setPage('ENTER_CODE')} />
		}
	}

	return <div>{renderSwitch()}</div>
}

export default ForgotPassword
