import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Arrow from '../assets/icons/Arrow'
import CancelPlan from '../components/cancel-plan'
import CancelPlanWarning from '../components/cancel-plan-warning'
import Button from '../components/core/button'
import PlanCard from '../components/plan-card'
import subscriptionPlans from '../constants/subscription-plans'
import getCurrentPlanFromSubscription from '../utils/get-current-plan-from-subscription'
import useGlobalState from '../utils/use-global-state'
import moment from 'moment'
import { SubscriptionPlan } from '../types/types'
import ChangePlan from '../components/change-plan'
import CancelWarningMain from '../assets/images/CancelWarningMain.jpg'
import Logo from '../assets/images/WHMLogo.jpg'
import AppStore from '../assets/icons/AppStore'

export type PlanSettingsPages = 'DEFAULT' | 'CANCEL_PLAN_WARNING' | 'CANCEL_PLAN' | 'CHANGE_PLAN'

function PlanSettings() {
	const navigate = useNavigate()

	const { state } = useGlobalState()
	const { user } = state

	const [page, setPage] = useState<PlanSettingsPages>('DEFAULT')
	const [newPlan, setNewPlan] = useState<SubscriptionPlan>()

	const renderOtherPlans = () => {
		const filteredPlans = subscriptionPlans.filter(
			(plan) => plan !== getCurrentPlanFromSubscription(user!.subscriptionDetails!.plan) && plan.canSubscribeTo
		)
		return filteredPlans.map((plan, index) => (
			<PlanCard
				key={index}
				setPlan={(plan) => {
					setNewPlan(plan)
					setPage('CHANGE_PLAN')
				}}
				plan={plan}
				isSelected={false}
			/>
		))
	}

	const renderSettings = () => {
		return (
			<>
				<div className='flex items-center justify-center relative mb-8 w-full'>
					<Arrow className='absolute left-0' onClick={() => navigate('/login')} />
					<div className='text-[20px] font-bold text-black-base leading-[24px]'>Plan Settings</div>
				</div>

				<div className='font-bold text-black-base'>Your Current Plan</div>

				{user?.subscriptionDetails && (
					<PlanCard isSelected setPlan={() => null} plan={getCurrentPlanFromSubscription(user.subscriptionDetails.plan)} />
				)}

				<div className='p-2 border text-[16px] mb-3 text-black-base border-grey-light rounded-lg mt-2'>
					{user?.subscriptionDetails?.cancel_at_period_end ? (
						<div>
							Subscription ends:&nbsp;
							{user?.subscriptionDetails && (
								<span className='font-bold text-purple-base'>{moment.unix(user!.subscriptionDetails.current_period_end).format('DD-MM-yyyy')}</span>
							)}
						</div>
					) : (
						<div>
							Next Payment:&nbsp;
							{user?.subscriptionDetails && (
								<span className='font-bold text-purple-base'>{moment.unix(user!.subscriptionDetails.current_period_end).format('DD-MM-yyyy')}</span>
							)}
						</div>
					)}
				</div>

				{!user?.subscriptionDetails?.cancel_at_period_end && (
					<Button
						text='Cancel Plan'
						onClick={() => setPage('CANCEL_PLAN_WARNING')}
						className='text-white mb-6 bg-error-base hover:bg-error-base border-white hover:border-white hover:text-white'
					/>
				)}

				<div className='font-bold text-black-base'>Change Plans:</div>
				<div className='text-black-base leading-5 mb-2'>
					Long term changes take time. The longer you're with us, the more you will save! Upgrade our WHM plan to save even more.
				</div>

				{renderOtherPlans()}
			</>
		)
	}

	const renderPaypalContent = () => {
		return (
			<div>
				<div className='flex items-center justify-center relative mb-8 w-full'>
					<Arrow className='absolute left-0' onClick={() => navigate('/')} />
					<img src={Logo} className='h-5' alt='WHM Logo' />
				</div>

				<div className='text-[30px] font-bold text-black-base leading-[24px] mb-2'>Plan Settings</div>
				<p className='text-[18px] leading-[20px] opacity-80 mb-8'>Login to your paypal account to update your WHM plan settings.</p>
			</div>
		)
	}

	const renderRevenueCatContent = () => {
		return (
			<div>
				<div className='flex items-center justify-center relative mb-8 w-full'>
					<Arrow className='absolute left-0' onClick={() => navigate('/')} />
					<img src={Logo} className='h-5' alt='WHM Logo' />
				</div>

				<div className='text-[30px] font-bold text-black-base leading-[24px] mb-4'>Plan Settings</div>
				<p className='text-[18px] leading-[20px] opacity-80 mb-8'>
					Please head to the WHM app to manage your subscription with the App or Play Store.
				</p>

				<div className='grid grid-cols-2 items-center mx-auto'>
					<a href='https://apps.apple.com/us/app/womens-health-movement/id1629535747' target='_blank'>
						<AppStore className='cursor-pointer' />
					</a>
					<a
						href='https://play.google.com/store/apps/details?id=com.womenshealthmovement&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
						target='_blank'
					>
						<img
							alt='Get it on Google Play'
							src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'
							className='w-[155px]'
						/>
					</a>
				</div>
			</div>
		)
	}

	const renderSwitch = () => {
		if (user?.userSubscriptionInformation?.type === 'RevenueCat') return renderRevenueCatContent()
		if (user?.userSubscriptionInformation?.type === 'PAYPAL') return renderPaypalContent()

		switch (page) {
			case 'DEFAULT':
				return renderSettings()
			case 'CANCEL_PLAN_WARNING':
				return <CancelPlanWarning setPage={setPage} />
			case 'CANCEL_PLAN':
				return <CancelPlan setPage={setPage} />
			case 'CHANGE_PLAN':
				return newPlan ? <ChangePlan newPlan={newPlan} setPage={setPage} /> : renderSettings()
			default:
				return renderSettings()
		}
	}

	return (
		<div className='flex flex-col min-h-screen'>
			<div className='px-4 py-8 md:h-auto flex flex-col'>{renderSwitch()}</div>
			{page === 'CANCEL_PLAN_WARNING' && (
				<div className='grow flex items-end'>
					<img src={CancelWarningMain} className='' alt='' />
				</div>
			)}
		</div>
	)
}

export default PlanSettings
