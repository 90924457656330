

import React from 'react'
import { IconBaseProps } from '../../types/types'

interface Props extends IconBaseProps {

}

function Tiktok({ className, onClick }: Props) {

  return (
    <div className={`${onClick ? 'cursor-pointer hover:scale-110 hover:rotate-3 transition-all duration-300 ' : ' '}` + className} onClick={onClick}>
      <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
        <g id="Group_402" data-name="Group 402" transform="translate(-557 -239)">
          <g id="Rectangle_569" data-name="Rectangle 569" transform="translate(557 239)" fill="rgba(214,161,86,0.3)" stroke="#5e257d" strokeWidth="2">
            <rect width="34" height="34" rx="10" stroke="none" />
            <rect x="1" y="1" width="32" height="32" rx="9" fill="none" />
          </g>
          <path id="Icon_simple-tiktok" data-name="Icon simple-tiktok" d="M11.785.017C12.911,0,14.029.009,15.147,0a5.356,5.356,0,0,0,1.5,3.585A6.064,6.064,0,0,0,20.3,5.125V8.59a9.2,9.2,0,0,1-3.611-.834,10.623,10.623,0,0,1-1.393-.8c-.009,2.511.009,5.021-.017,7.524a6.567,6.567,0,0,1-1.161,3.388,6.406,6.406,0,0,1-5.082,2.76,6.27,6.27,0,0,1-3.508-.886,6.484,6.484,0,0,1-3.138-4.91c-.017-.43-.026-.86-.009-1.281A6.473,6.473,0,0,1,9.884,7.807C9.9,9.08,9.85,10.352,9.85,11.625a2.95,2.95,0,0,0-3.766,1.823,3.411,3.411,0,0,0-.12,1.384A2.926,2.926,0,0,0,8.973,17.3a2.889,2.889,0,0,0,2.382-1.384A1.984,1.984,0,0,0,11.707,15c.086-1.539.052-3.07.06-4.609.009-3.465-.009-6.922.017-10.378Z" transform="translate(562.669 245.682)" fill="#5e257d" />
        </g>
      </svg>



    </div>
  )
}

export default Tiktok