import { useState } from 'react'
import Arrow from '../assets/icons/Arrow'
import Help from '../assets/icons/Help'
import Logo from '../assets/images/WHMLogo.jpg'
import Button from './core/button'
import VerificationCodeInput from './verification-code-input'

type Props = {
    source: string
    verifyCode: (code: string) => void
    isLoading: boolean
    backHandler?: () => void
    hideTips?: boolean
    resendCode: () => void
}

function VerificationCode({ source, verifyCode, isLoading, backHandler, hideTips, resendCode }: Props) {

    const [canResendCode, setCanResendCode] = useState(true)
    
    const handleCodeComplete = (code: string) => {
        verifyCode(code)
    }

    const handleResendCode = () => {
        setCanResendCode(false)
        resendCode()

        setTimeout(() => {
            setCanResendCode(true)
        }, 10000)
    }

    return (
        <div className='px-4 py-8 h-screen md:h-auto flex flex-col'>
            <div className='flex items-center justify-center relative mb-8 w-full'>
                {backHandler && <Arrow className='absolute left-0' onClick={backHandler}/>}
                
                <img src={Logo} className='h-5' alt='WHM Logo'/>
            </div>

            <div className='text-[30px] font-bold text-black-base mb-2 leading-[35px]'>Verification Code</div>
            <div className='text-[18px] text-black-light leading-5 mb-6'>Enter the code we sent to <span className='font-semibold'>{source}</span> to verify your device.</div>
            <VerificationCodeInput length={6} handleCodeComplete={handleCodeComplete}/>
            {canResendCode 
            && <div className='text-[18px] text-black-light leading-5 mb-6'>Didn't receive a code? <span className='font-semibold cursor-pointer' onClick={handleResendCode}>Send Another</span></div>}
            

            {!hideTips && <div className='bg-[#deacf81a] p-3 rounded-[10px] mb-6'>
                <div className='flex justify-between mb-2'>
                    <div className='font-semibold text-[20px]'>Why do we need this?</div>
                    <Help onClick={() => null}/>
                </div>
                <div className='text-[16px] text-black-light leading-5'>We use your mobile number to verify you are a real person. We also use your mobile number to sign you into your account.</div>
            </div>}

            

            <div className='grow flex items-end'>
                <Button text='Next' onClick={() => null} isLoading={isLoading} />

            </div>

        </div>
    )
}

export default VerificationCode
