import React from 'react'
import ProfileEditDetails from '../components/profile-edit-details'
import Logo from '../assets/images/WHMLogo.jpg'
import Arrow from '../assets/icons/Arrow'
import { useNavigate } from 'react-router-dom'

function PersonalSettings() {

    const navigate = useNavigate()

    return (
        <div className='px-4 py-8 h-screen md:h-auto flex flex-col'>
            <div className='flex items-center justify-center relative mb-8 w-full'>
                <Arrow className='absolute left-0' onClick={() => navigate('/login')}/>
                <div className='text-[20px] font-bold text-black-base leading-[24px]'>Personal Settings</div>
            </div>

            <ProfileEditDetails pageType='EDIT' />

        </div>
    )
}

export default PersonalSettings
