import React from 'react'
import Arrow from '../assets/icons/Arrow'
import { PlanSettingsPages } from '../pages/plan-settings'
import Logo from '../assets/images/WHMLogo.jpg'
import Button from './core/button'

type Props = {
	setPage: (planSettingPage: PlanSettingsPages) => void
}

function CancelPlanWarning({ setPage }: Props) {
	return (
		<>
			<div className='flex items-center justify-center relative mb-8 w-full'>
				<Arrow className='absolute left-0' onClick={() => setPage('DEFAULT')} />
				<img src={Logo} className='h-5' alt='WHM Logo' />
			</div>

			<div className='font-bold text-2xl mb-6 text-black-base'>We're sad to see you go!</div>

			<div className='leading-5 text-[16px] mb-4 text-black-base'>
				A break-up is never easy, we would hate to see you leave us. By cancelling your subscription you will lose access to the Womens Health
				Movement app for now.
			</div>
			<div className='leading-5 text-[16px] mb-12 text-black-base'>We hope to see you again in the future!</div>
			<Button
				text='Unsubscribe'
				onClick={() => setPage('CANCEL_PLAN')}
				className='bg-white hover:bg-white text-gold-base hover:text-gold-base'
			/>
		</>
	)
}

export default CancelPlanWarning
