import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Arrow from '../assets/icons/Arrow'
import StripeService from '../services/stripe'
import asyncTimeout from '../utils/asyncTimeout'
import errorPopupParser from '../utils/error-popup-parser'
import useGlobalState from '../utils/use-global-state'
import Button from './core/button'
import ModalWrapper from './core/modal-wrapper'
import PaymentField from './payment-field'
import { PaymentMethod } from '@stripe/stripe-js'

type Props = {
	isOpen: boolean
	setIsOpen: (isOpen: boolean) => void
	getPaymentMethods: () => Promise<void>
}

function AddPaymentMethodModal({ isOpen, setIsOpen, getPaymentMethods }: Props) {
	const { dispatch } = useGlobalState()
	const stripe = new StripeService()

	const [paymentMethodId, setPaymentMethodId] = useState<PaymentMethod>()
	const [isLoading, setIsLoading] = useState(false)

	const addPaymentMethod = async () => {
		if (!paymentMethodId) return
		setIsLoading(true)

		try {
			await Promise.all([stripe.addPaymentMethod(paymentMethodId.id), asyncTimeout(800)])
			await getPaymentMethods()
			setIsOpen(false)
		} catch (error) {
			errorPopupParser(error, dispatch)
		} finally {
			setIsLoading(false)
		}
	}

	return (
		<ModalWrapper isOpen={isOpen} setIsOpen={setIsOpen}>
			<div className='absolute bottom-0 bg-white pb-16' onClick={(e) => e.stopPropagation()}>
				<div className='flex items-center p-3 border-b border-grey-base88 font-bold'>
					<Arrow onClick={() => setIsOpen(false)} />
					<div className='mx-auto text-[20px] text-black-base'>Add Payment Method</div>
				</div>

				<div className='p-3 text-[18px] text-black-base opacity-80 leading-[20px]'>Add a new payment method to your WHM account.</div>

				<div className='px-3'>
					<PaymentField setPaymentMethodId={setPaymentMethodId} />
				</div>

				<div className='mx-3 mb-8'>
					<Button text='Add Method' isLoading={isLoading} onClick={() => addPaymentMethod()} className='' />
				</div>
			</div>
		</ModalWrapper>
	)
}

export default AddPaymentMethodModal
