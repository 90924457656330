import { Slide } from '@mui/material'
import React, { useEffect } from 'react'

type Props = {
    isOpen: boolean
    setIsOpen: (isOpen: boolean) => void
    children: JSX.Element
}

function ModalWrapper({ isOpen, setIsOpen, children }: Props) {

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden'
            window.scrollTo(0, 0)
        }
        else document.body.style.overflow = 'unset'
    }, [isOpen])

    useEffect(() => {
        return () => {
            document.body.style.overflow = 'unset'
        }
    }, [])

    return (
        <Slide in={isOpen} timeout={500} direction='up' unmountOnExit mountOnEnter>
            <div className='absolute h-screen bg-[#5E257D33] z-10 w-full top-0 left-0 max-w-md flex flex-col' onClick={() => setIsOpen(false)}>
                {children}
            </div>
        </Slide>
    )
}

export default ModalWrapper
