import React, { ClipboardEventHandler, KeyboardEvent, Ref } from 'react'

type Props = {
    placeholder?: string
    className?: string
    value: string
    onChange: (text: string) => void
    type?: 'NUMBER' | 'TEXT'
    reference?: Ref<HTMLInputElement>
    onKeyUp?: (e: KeyboardEvent) => void
    autoFocus?: boolean
    onPaste?: ClipboardEventHandler
    disabled?: boolean
}

function TextInput({ placeholder, className, value, onChange, type, reference, onKeyUp, autoFocus, onPaste, disabled }: Props): JSX.Element {

    const validateIsNumber = (text: string) => {
        if (isNaN(Number(text))) return
        onChange(text)
    }

    return (
        <input className={`input-primary ` + className} type='text' placeholder={placeholder} value={value} 
        onChange={e => type === 'NUMBER' ?
        validateIsNumber(e.target.value)
        : onChange(e.target.value)}
        ref={reference}
        onKeyUp={onKeyUp}
        autoFocus={autoFocus}
        onPaste={onPaste}
        disabled={disabled}
        >
            
        </input>
    )
}

export default TextInput
