import Arrow from '../assets/icons/Arrow'
import { SubscribePage, SubscriptionPlan } from '../types/types'
import Button from './core/button'
import subscriptionPlans from '../constants/subscription-plans'
import PlanCard from './plan-card'
import SubscribePlanMain from '../assets/images/SubscribePlanMain.png'
import SubscribeTopBG from '../assets/images/SubscribeTopBG.png'
import { useNavigate } from 'react-router-dom'

type Props = {
	setPage: (page: SubscribePage) => void
	setPlan: (plan: SubscriptionPlan) => void
	selectedPlan: SubscriptionPlan | undefined
}

const appBenefits = [
	'Gym & home programs to suit every goal.',
	'Beginner, intermediate & advanced training options with the ability to tailor your workout.',
	"Micro-learning modules on women's health, nutrition, mindset & training.",
	'Integration with health apps to track nutrition, steps and sleep.',
	'Connect with a community of like-minded women.',
]

function SubscribePlan({ setPage, setPlan, selectedPlan }: Props) {
	const navigate = useNavigate()

	const filteredPlans = subscriptionPlans.filter((plan) => plan.canSubscribeTo)
	const renderPlanCards = () => {
		return filteredPlans.map((plan, index) => (
			<PlanCard key={index} plan={plan} setPlan={setPlan} isSelected={selectedPlan === plan} isAllPlansView />
		))
	}

	const renderDotPoints = () => {
		return appBenefits.map((benefit) => {
			return (
				<div className='flex gap-1'>
					<li className='leading-[22px] mb-1'></li>
					<p>{benefit}</p>
				</div>
			)
		})
	}

	return (
		<>
			<Arrow className='absolute left-6 bg-white p-2 rounded-md scale-75 z-10' onClick={() => navigate('/')} />
			<div className='absolute top-0 left-0 w-full'>
				<img src={SubscribeTopBG} />
				<img src={SubscribePlanMain} className='absolute top-0' />
			</div>

			<div className='h-[280px]' />

			<div className='text-[30px] font-bold text-[#333333] '>Everything In One Place</div>

			<ul className='mb-6 list-inside text-[14px] text-[#333333CC]'>{renderDotPoints()}</ul>

			<div className='mb-8'>{renderPlanCards()}</div>

			<Button text='Next' disabled={selectedPlan === undefined} onClick={() => setPage('PAYMENT')} className='mb-4 py-1' />
		</>
	)
}

export default SubscribePlan
