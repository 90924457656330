import axios, { AxiosResponse } from 'axios'
import {
	CredentialDetailsEmail,
	CredentialDetailsMobile,
	PresignedUpload,
	SendVerificationResult,
	UploadFolder,
	VerificationCodeOperation,
} from '../types/types'
import Instance from '../utils/axios'

const networkErrorMessage = 'There was an error with your connection, please try again'

class VerificationService {
	sendVerificationCode = async (credentialDetails: CredentialDetailsMobile | CredentialDetailsEmail): Promise<SendVerificationResult> => {
		try {
			const result = await Instance.post('verification-code/send', {
				credentialDetails,
			})

			return {
				type: 'login',
				data: result.data,
			}
		} catch (error) {
			if (error && axios.isAxiosError(error)) {
				if (error?.code === 'ERR_NETWORK' || error?.code === 'ECONNABORTED') throw Error(networkErrorMessage)
			}
			return {
				type: 'register',
				data: error,
			}
		}
	}

	checkCode = async (
		credentialDetails: CredentialDetailsMobile | CredentialDetailsEmail,
		code: string,
		operation: VerificationCodeOperation
	): Promise<AxiosResponse> => {
		try {
			const result = await Instance.post('verification-code/check', {
				credentialDetails,
				code,
				operation,
			})
			return result
		} catch (error) {
			if (error && axios.isAxiosError(error)) {
				if (error?.code === 'ERR_NETWORK' || error?.code === 'ECONNABORTED') throw Error(networkErrorMessage)
			}

			throw new Error('Incorrect code')
		}
	}
}

export default VerificationService
