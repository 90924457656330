

import React from 'react'
import { IconBaseProps } from '../../types/types'

interface Props extends IconBaseProps {

}

function Camera({ className, onClick }: Props) {

  return (
    <div className={`${onClick ? 'cursor-pointer hover:scale-110 hover:rotate-3 transition-all duration-300 ' : ' '}` + className} onClick={onClick}>
      <svg id="add_a_photo_black_24dp" xmlns="http://www.w3.org/2000/svg" width="55.758" height="55.758" viewBox="0 0 55.758 55.758">
        <path id="Path_61" data-name="Path 61" d="M0,0H55.758V55.758H0Z" fill="none" />
        <path id="Path_62" data-name="Path 62" d="M7.323,8.323v6.97H5V38.525H42.172V10.646H32.763L28.511,6H14.293V8.323ZM23.586,12.97A11.616,11.616,0,1,1,11.97,24.586,11.62,11.62,0,0,1,23.586,12.97Z" transform="translate(6.616 7.939)" fill="#d6a156" opacity="0.3" />
        <path id="Path_63" data-name="Path 63" d="M48.788,12.616H41.423L37.172,7.97H20.909v4.646H35.127l4.252,4.646h9.409V45.142H11.616V21.909H6.97V45.142a4.66,4.66,0,0,0,4.646,4.646H48.788a4.66,4.66,0,0,0,4.646-4.646V17.263A4.66,4.66,0,0,0,48.788,12.616ZM18.586,31.2A11.616,11.616,0,1,0,30.2,19.586,11.62,11.62,0,0,0,18.586,31.2ZM30.2,24.232a6.97,6.97,0,1,1-6.97,6.97A6.99,6.99,0,0,1,30.2,24.232ZM11.616,19.586v-6.97h6.97V7.97h-6.97V1H6.97V7.97H0v4.646H6.97v6.97Z" transform="translate(0 1.323)" fill="#5e257d" />
      </svg>


    </div>
  )
}

export default Camera