

import React from 'react'
import { IconBaseProps } from '../../types/types'

interface Props extends IconBaseProps {

}

function AboutUs({ className, onClick }: Props) {

  return (
    <div className={`${onClick ? 'cursor-pointer hover:scale-110 hover:rotate-3 transition-all duration-300 ' : ' '}` + className} onClick={onClick}>
      <svg id="help_black_24dp" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
        <path id="Path_7" data-name="Path 7" d="M0,0H30V30H0Z" fill="none" />
        <path id="Path_8" data-name="Path 8" d="M13.995,4a9.995,9.995,0,1,0,9.995,9.995A10.008,10.008,0,0,0,13.995,4Zm1.249,17.491h-2.5v-2.5h2.5Zm0-3.748h-2.5c0-4.06,3.748-3.748,3.748-6.247a2.5,2.5,0,1,0-5,0H9a5,5,0,1,1,9.995,0C18.992,14.619,15.244,14.932,15.244,17.743Z" transform="translate(1.005 1.005)" fill="#d6a156" opacity="0.3" />
        <path id="Path_9" data-name="Path 9" d="M13.248,19.5h2.5V22h-2.5ZM14.5,2a12.5,12.5,0,1,0,12.5,12.5A12.5,12.5,0,0,0,14.5,2Zm0,22.5a10,10,0,1,1,10-10A10.011,10.011,0,0,1,14.5,24.5ZM14.5,7a5,5,0,0,0-5,5H12a2.5,2.5,0,1,1,5,0c0,2.5-3.749,2.187-3.749,6.249h2.5c0-2.812,3.749-3.124,3.749-6.249A5,5,0,0,0,14.5,7Z" transform="translate(0.503 0.503)" fill="#5e257d" />
      </svg>

    </div>
  )
}

export default AboutUs