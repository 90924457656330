import React from 'react'
import CreditCard from '../assets/icons/CreditCard'
import { PaymentMethod } from '@stripe/stripe-js'

type Props = {
    isDefault?: boolean
    paymentMethod: PaymentMethod
    setSelectedPaymentMethod?: (paymentMethod: PaymentMethod) => void
}

function PaymentMethodCard({ isDefault, paymentMethod, setSelectedPaymentMethod }: Props) {
    return (
        <div className={`flex items-center gap-4 border-2 p-3 mb-4 rounded-lg ${isDefault ? 'border-purple-base' : ' border-grey-light cursor-pointer hover:shadow-lg'} transition-all duration-500 select-none`} onClick={() => setSelectedPaymentMethod ? setSelectedPaymentMethod(paymentMethod) : null}>
            <CreditCard />
            <div className=''>
                <p className='text-[18px] font-bold text-purple-base opacity-80 leading-5 capitalize'>{paymentMethod.card?.brand} - {paymentMethod.card?.funding}</p>
                <p className='text-[16px] text-black-base opacity-60 leading-[18px]'>XXXX XXXX XXXX {paymentMethod.card?.last4}</p>
            </div>
        </div>
    )
}

export default PaymentMethodCard
