import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Arrow from '../assets/icons/Arrow'
import Button from './core/button'
import Logo from '../assets/images/WHMLogo.jpg'
import PhoneValidationInput from './core/phone-validation-input'
import { useFormik } from 'formik'
import parseMobileNumber from '../utils/parse-mobile-number'
import { loginMobileSchema } from '../utils/validation-schemas'
import AuthService from '../services/auth'
import VerificationCode from './verficiation-code'
import errorPopupParser from '../utils/error-popup-parser'
import useGlobalState from '../utils/use-global-state'
import asyncTimeout from '../utils/asyncTimeout'
import VerificationService from '../services/verification'
import { CredentialDetailsEmail, CredentialDetailsMobile } from '../types/types'

type Props = {
	goBackToSettings: () => void
}

type ChangeMobilePages = 'ENTER_NUMBER' | 'ENTER_CODE'

function ChangeMobile({ goBackToSettings }: Props) {
	const navigate = useNavigate()
	const auth = new AuthService()
	const verify = new VerificationService()
	const { dispatch, state } = useGlobalState()

	const [isLoading, setIsLoading] = useState(false)
	const [page, setPage] = useState<ChangeMobilePages>('ENTER_NUMBER')

	const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
		initialValues: {
			phoneNumber: '',
			countryCode: {
				countryCode: 'au',
				dialCode: '61',
				format: '+.. (..) .... ....',
				name: 'Australia',
			},
		},
		onSubmit: (values) => {
			sendVerificationCode(parseMobileNumber(values.phoneNumber, values.countryCode.countryCode), values.countryCode.dialCode)
		},
		validationSchema: loginMobileSchema,
	})

	const sendVerificationCode = async (number: string, countryCode: string) => {
		setIsLoading(true)

		const credentialDetails: CredentialDetailsEmail | CredentialDetailsMobile = {
			number,
			countryCode,
		}

		try {
			await verify.sendVerificationCode(credentialDetails)
			setPage('ENTER_CODE')
		} catch (error) {
		} finally {
			setIsLoading(false)
		}
	}

	const renderMobileInput = () => {
		return (
			<div className='px-4 py-8 h-screen md:h-auto flex flex-col'>
				<div className='flex items-center justify-center relative mb-12 w-full'>
					<Arrow className='absolute left-0' onClick={() => goBackToSettings()} />
					<img src={Logo} className='h-5' alt='WHM Logo' />
				</div>
				<div className='text-[30px] font-bold text-black-base mb-2 leading-[35px]'>Change Mobile Number</div>
				<div className='text-[18px] text-black-light mb-4 leading-5'>Update the mobile number connected to your WHM account.</div>

				<PhoneValidationInput
					phoneValue={values.phoneNumber}
					setFieldValue={setFieldValue}
					phoneError={errors.phoneNumber}
					touched={touched.phoneNumber}
				/>

				<div className='grow flex items-end'>
					<Button text='Next' onClick={handleSubmit} isLoading={isLoading} />
				</div>
			</div>
		)
	}

	const updateMobileNumber = async (code: string) => {
		if (!state.user) return

		setIsLoading(true)
		const newPhoneDetails = {
			changePhoneNumber: {
				credentialDetails: {
					number: parseMobileNumber(values.phoneNumber, values.countryCode.countryCode),
					countryCode: values.countryCode.dialCode,
				},
				code,
			},
		}

		const credentialDetails: CredentialDetailsEmail | CredentialDetailsMobile = {
			number: parseMobileNumber(values.phoneNumber, values.countryCode.countryCode),
			countryCode: values.countryCode.dialCode,
		}

		try {
			await Promise.all([verify.checkCode(credentialDetails, code, 'change_phone_number'), asyncTimeout(900)])

			await auth.updateOne(newPhoneDetails, state.user.id)

			dispatch({
				type: 'setSnack',
				data: {
					isOpen: true,
					message: 'Your mobile number has been changed succesfully',
					severity: 'success',
				},
			})

			goBackToSettings()
		} catch (error) {
			errorPopupParser(error, dispatch)
		} finally {
			setIsLoading(false)
		}
	}

	const renderSwitch = (): JSX.Element => {
		switch (page) {
			case 'ENTER_NUMBER':
				return renderMobileInput()
			case 'ENTER_CODE':
				return (
					<VerificationCode
						hideTips
						source={values.phoneNumber}
						verifyCode={updateMobileNumber}
						isLoading={isLoading}
						backHandler={() => setPage('ENTER_NUMBER')}
						resendCode={() =>
							sendVerificationCode(parseMobileNumber(values.phoneNumber, values.countryCode.countryCode), values.countryCode.dialCode)
						}
					/>
				)
		}
	}

	return <div>{renderSwitch()}</div>
}

export default ChangeMobile
