import Button from './core/button'
import OnboardMain from '../assets/images/OnboardMain.png'
import { LoginPageNames } from '../pages/login'

type Props = {
	setPage: (pageName: LoginPageNames) => void
}

function Onboard({ setPage }: Props) {
	return (
		<div className='h-screen md:h-auto flex flex-col select-none'>
			<img src={OnboardMain} className='' alt='' />

			<div className='px-4 pb-8'>
				<div className='text-[30px] font-bold text-black-base mb-2 leading-[35px]'>More Than a Fitness App.</div>
				<div className='text-[18px] text-[#333333CC] leading-5 mb-6'>
					No matter where you're at in your health & fitness journey, you need a structured approach if you're serious about achieving your
					goals.
				</div>
				<div className='text-[18px] text-[#333333CC] leading-5 mb-6'>
					Start adopting a new mindset where you fuel your body for performance, train with purpose, and seek the knowledge to help you make all
					of these changes sustainable.
				</div>

				<div className='grow flex flex-col gap-2 items-end'>
					<Button text='Create an Account' onClick={() => setPage('REGISTER')} />
					<Button
						text='Sign In'
						onClick={() => setPage('MOBILE_LOGIN')}
						className='bg-white border-none text-gold-base hover:text-gold-base hover:bg-white'
					/>
				</div>
			</div>
		</div>
	)
}

export default Onboard
