

import React from 'react'
import { IconBaseProps } from '../../types/types'

interface Props extends IconBaseProps {

}

function Instagram({ className, onClick }: Props) {

  return (
    <div className={`${onClick ? 'cursor-pointer hover:scale-110 hover:rotate-3 transition-all duration-300 ' : ' '}` + className} onClick={onClick}>
      <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
        <g id="Group_401" data-name="Group 401" transform="translate(-528 -239)">
          <g id="Rectangle_568" data-name="Rectangle 568" transform="translate(528 239)" fill="rgba(214,161,86,0.3)" stroke="#5e257d" strokeWidth="2">
            <rect width="34" height="34" rx="10" stroke="none" />
            <rect x="1" y="1" width="32" height="32" rx="9" fill="none" />
          </g>
          <path id="Icon_awesome-instagram" data-name="Icon awesome-instagram" d="M11.106,7.649a5.7,5.7,0,1,0,5.7,5.7A5.686,5.686,0,0,0,11.106,7.649Zm0,9.4a3.7,3.7,0,1,1,3.7-3.7,3.709,3.709,0,0,1-3.7,3.7Zm7.257-9.631a1.328,1.328,0,1,1-1.328-1.328A1.325,1.325,0,0,1,18.363,7.416Zm3.772,1.348A6.574,6.574,0,0,0,20.34,4.11a6.617,6.617,0,0,0-4.654-1.794c-1.834-.1-7.331-.1-9.165,0A6.608,6.608,0,0,0,1.867,4.105,6.6,6.6,0,0,0,.073,8.759c-.1,1.834-.1,7.331,0,9.165a6.574,6.574,0,0,0,1.794,4.654,6.625,6.625,0,0,0,4.654,1.794c1.834.1,7.331.1,9.165,0a6.574,6.574,0,0,0,4.654-1.794,6.617,6.617,0,0,0,1.794-4.654c.1-1.834.1-7.326,0-9.16ZM19.765,19.892A3.749,3.749,0,0,1,17.654,22c-1.462.58-4.932.446-6.548.446s-5.09.129-6.548-.446a3.749,3.749,0,0,1-2.112-2.112C1.867,18.43,2,14.96,2,13.344s-.129-5.09.446-6.548A3.749,3.749,0,0,1,4.559,4.685c1.462-.58,4.932-.446,6.548-.446s5.09-.129,6.548.446A3.749,3.749,0,0,1,19.765,6.8c.58,1.462.446,4.932.446,6.548S20.345,18.435,19.765,19.892Z" transform="translate(533.987 242.748)" fill="#5e257d" />
        </g>
      </svg>



    </div>
  )
}

export default Instagram