import { SubscriptionPlan } from '../types/types'

const subscriptionPlans: SubscriptionPlan[] = [
	{
		price: 25,
		subscriptionPeriodText: 'TWS Special Offer / Monthly',
		stripePriceId: process.env.REACT_APP_STRIPE_PRICE_TWS_OFFER ?? '',
		paypalPlanId: process.env.REACT_APP_PAYPAL_PLAN_TWS_OFFER ?? '',
		isFreeTrial: false,
		billingPeriod: 'AUD Billed Yearly',
		canSubscribeTo: false,
	},
	{
		price: 299.99,
		subscriptionPeriodText: 'Annual',
		stripePriceId: process.env.REACT_APP_STRIPE_PRICE_YEARLY ?? '',
		paypalPlanId: process.env.REACT_APP_PAYPAL_PLAN_YEARLY ?? '',
		isFreeTrial: true,
		billingPeriod: '($24.99 AUD/month, billed yearly)',
		canSubscribeTo: true,
		saveText: 'Save $179.89',
	},
	{
		price: 179.99,
		subscriptionPeriodText: '6 Monthly',
		stripePriceId: process.env.REACT_APP_STRIPE_PRICE_SIX_MONHTLY ?? '',
		paypalPlanId: process.env.REACT_APP_PAYPAL_PLAN_SIX_MONHTLY ?? '',
		isFreeTrial: true,
		billingPeriod: '($29.99 AUD/month, billed 6 monthly)',
		canSubscribeTo: true,
		saveText: 'Save $59.95',
	},
	{
		price: 39.99,
		subscriptionPeriodText: 'Monthly',
		stripePriceId: process.env.REACT_APP_STRIPE_PRICE_MONTHLY ?? '',
		paypalPlanId: process.env.REACT_APP_PAYPAL_PLAN_MONTHLY ?? '',
		isFreeTrial: true,
		billingPeriod: '($39.99 AUD/month, billed monthly)',
		canSubscribeTo: true,
	},
]

export default subscriptionPlans
