

import React from 'react'
import { IconBaseProps } from '../../types/types'

interface Props extends IconBaseProps {

}

function Logout({ className, onClick }: Props) {

  return (
    <div className={`${onClick ? 'cursor-pointer hover:scale-110 hover:rotate-3 transition-all duration-300 ' : ' '}` + className} onClick={onClick}>
      <svg id="logout_black_24dp" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
        <g id="Group_695" data-name="Group 695">
          <path id="Path_759" data-name="Path 759" d="M0,0H30V30H0Z" fill="none" />
        </g>
        <g id="Group_696" data-name="Group 696" transform="translate(3.75 3.75)">
          <path id="Path_760" data-name="Path 760" d="M20.5,9.25l-1.763,1.762L20.712,13H10.5v2.5H20.712l-1.975,1.975L20.5,19.25l5-5ZM5.5,5.5h8.75V3H5.5A2.507,2.507,0,0,0,3,5.5V23a2.507,2.507,0,0,0,2.5,2.5h8.75V23H5.5Z" transform="translate(-3 -3)" fill="#5e257d" />
        </g>
      </svg>

    </div>
  )
}

export default Logout