import { Snack, User } from '../../types/types'

export type GlobalStateAction = 
{ type: 'setUser', data: User | undefined } |
{ type: 'setSnack', data: Snack }

export const globalInitialState: GlobalState = {
    user: undefined,
    snack: {
        isOpen: false,
        severity: 'success',
        message: ''
    }
}

export type GlobalState = {
    user: User | undefined
    snack: Snack
}

export default function globalReducer(state: GlobalState, action: GlobalStateAction): GlobalState {
    switch (action.type) {
        case 'setUser': {
            return {
                ...state,
                user: action.data
            }
        }
        case 'setSnack': {
            return {
                ...state,
                snack: action.data
            }
        }




        default: return state
    }
}