

import React from 'react'
import { IconBaseProps } from '../../types/types'

interface Props extends IconBaseProps {

}

function Minus({ className, onClick }: Props) {

  return (
    <div className={`${onClick ? 'cursor-pointer hover:scale-110 hover:rotate-3 transition-all duration-300 ' : ' '}` + className} onClick={onClick}>
      <svg id="remove_circle_black_24dp" xmlns="http://www.w3.org/2000/svg" width="39.678" height="39.678" viewBox="0 0 39.678 39.678">
        <circle id="Ellipse_49" data-name="Ellipse 49" cx="16" cy="16" r="16" transform="translate(3.678 3.678)" fill="#fff" />
        <path id="Path_54" data-name="Path 54" d="M0,0H39.678V39.678H0Z" fill="none" />
        <path id="Path_55" data-name="Path 55" d="M17.226,4A13.226,13.226,0,1,0,30.452,17.226,13.243,13.243,0,0,0,17.226,4Zm8.266,14.879H8.96V15.573H25.492Z" transform="translate(2.613 2.613)" fill="#d6a156" opacity="0.3" />
        <path id="Path_56" data-name="Path 56" d="M10.266,16.879H26.8v3.306H10.266ZM18.532,2A16.532,16.532,0,1,0,35.065,18.532,16.538,16.538,0,0,0,18.532,2Zm0,29.758A13.226,13.226,0,1,1,31.758,18.532,13.243,13.243,0,0,1,18.532,31.758Z" transform="translate(1.306 1.306)" fill="#5e257d" />
      </svg>
    </div>
  )
}

export default Minus