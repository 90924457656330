import React from 'react'
import Logo from '../assets/images/WHMIconTwoTone.jpg'

function Loading() {
  return (
    <div className='w-screen h-screen flex justify-center items-center'>
      <img src={Logo} className=' w-1/2 animate-pulse max-w-[600px]' alt='WHM Logo'/>
    </div>
  )
}

export default Loading