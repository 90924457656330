import React from 'react'
import { IconBaseProps } from '../../types/types'

interface Props extends IconBaseProps {
	alignment?: 'right' | 'left' | 'top' | 'bottom'
}

function ArrowLong({ className, onClick, alignment }: Props) {
	const getRotationValue = () => {
		switch (alignment) {
			case 'right':
				return 'rotate(180deg)'
		}
	}

	return (
		<div
			className={`${onClick ? 'cursor-pointer hover:scale-110 hover:rotate-3 transition-all duration-300 ' : ' '}` + className}
			onClick={onClick}
			style={{ transform: getRotationValue() }}
		>
			<svg width='37' height='24' viewBox='0 0 37 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
				<path
					d='M36.0607 13.0607C36.6464 12.4749 36.6464 11.5251 36.0607 10.9393L26.5147 1.3934C25.9289 0.807614 24.9792 0.807614 24.3934 1.3934C23.8076 1.97919 23.8076 2.92893 24.3934 3.51472L32.8787 12L24.3934 20.4853C23.8076 21.0711 23.8076 22.0208 24.3934 22.6066C24.9792 23.1924 25.9289 23.1924 26.5147 22.6066L36.0607 13.0607ZM-1.26379e-07 13.5L35 13.5L35 10.5L1.26379e-07 10.5L-1.26379e-07 13.5Z'
					fill='currentColor'
				/>
			</svg>
		</div>
	)
}

export default ArrowLong
