import React, { useEffect, useRef, useState } from 'react'
import Arrow from '../assets/icons/Arrow'
import Button from './core/button'
import Logo from '../assets/images/WHMLogo.jpg'
import { PlanSettingsPages } from '../pages/plan-settings'
import VerificationCode from './verficiation-code'
import useGlobalState from '../utils/use-global-state'
import VerificationCodeInput from './verification-code-input'
import { CredentialDetailsEmail, CredentialDetailsMobile } from '../types/types'
import VerificationService from '../services/verification'
import errorPopupParser from '../utils/error-popup-parser'
import moment from 'moment'
import StripeService from '../services/stripe'
import { useNavigate } from 'react-router-dom'
import refreshUserData from '../utils/refresh-user-data'

type Props = {
	setPage: (planSettingPage: PlanSettingsPages) => void
}

function CancelPlan({ setPage }: Props) {
	const { state, dispatch } = useGlobalState()
	const { user } = state

	const verify = new VerificationService()
	const stripe = new StripeService()
	const navigate = useNavigate()

	const [isLoading, setIsLoading] = useState(false)
	const [canResendCode, setCanResendCode] = useState(false)
	const [code, setCode] = useState('')

	const hasSentCode = useRef(false)

	const verifyCode = async (code: string) => {
		setIsLoading(true)

		const credentialDetails: CredentialDetailsEmail | CredentialDetailsMobile = {
			number: user!.userMobile.number,
			countryCode: user!.userMobile.countryCode,
		}

		try {
			await verify.checkCode(credentialDetails, code, 'new_subscription')
			setCode(code)
		} catch (error) {
			errorPopupParser(error, dispatch)
		} finally {
			setIsLoading(false)
		}
	}

	const unsubscribeUser = async () => {
		setIsLoading(true)

		try {
			await stripe.deleteSubscription(code, user!.id)

			dispatch({
				type: 'setSnack',
				data: {
					message: 'You have unsubscribed successfully',
					severity: 'success',
					isOpen: true,
				},
			})

			refreshUserData(dispatch)

			setTimeout(() => {
				navigate('/')
			}, 2000)
		} catch (error) {
			errorPopupParser(error, dispatch)
			setIsLoading(false)
		}
	}

	const sendVerificationCode = async () => {
		setIsLoading(true)
		setCanResendCode(false)

		const credentialDetails: CredentialDetailsEmail | CredentialDetailsMobile = {
			number: user!.userMobile.number,
			countryCode: user!.userMobile.countryCode,
		}

		try {
			await verify.sendVerificationCode(credentialDetails)
		} catch (error) {
			errorPopupParser(error, dispatch)
		} finally {
			setIsLoading(false)
			setTimeout(() => {
				setCanResendCode(true)
			}, 10000)
		}
	}

	const renderPlanEndDate = () => {
		if (!user?.subscriptionDetails?.current_period_end) return '--'
		else return moment.unix(user!.subscriptionDetails!.current_period_end).format('DD-MM-yyyy')
	}

	useEffect(() => {
		if (hasSentCode.current) return

		if (user) sendVerificationCode()

		if (!hasSentCode.current) hasSentCode.current = true
	}, [user])

	return (
		<>
			<div className='flex items-center justify-center relative mb-8 w-full'>
				<Arrow className='absolute left-0' onClick={() => setPage('CANCEL_PLAN_WARNING')} />
				<img src={Logo} className='h-5' alt='WHM Logo' />
			</div>

			<div className='font-bold text-[30px] text-black-base'>Authentication</div>

			<div className='leading-5 text-[18px] text-black-base mb-2'>
				We have sent a one time code to the mobile associated with your account
				<span className='font-bold'> {user!.userMobile.countryCode + user!.userMobile.number}</span>. Enter the code to authenticate these
				changes.
			</div>

			<div className='p-2 border rounded-lg border-grey-light leading-5 text-black-base mb-4 opacity-60'>
				By authenticating yourself you will cancel your WHM subscription. You will have access to the app until your current period ends of the:{' '}
				<span className='font-bold'>{renderPlanEndDate()}</span>
			</div>

			<VerificationCodeInput length={6} handleCodeComplete={verifyCode} disabled={code !== ''} />
			{canResendCode && (
				<div className='text-[18px] text-black-light leading-5 mb-6'>
					Didn't receive a code?{' '}
					<span className='font-semibold cursor-pointer' onClick={sendVerificationCode}>
						Send Another
					</span>
				</div>
			)}

			<div className='grow flex items-end'>
				<Button text='Unsubscribe' disabled={!code} isLoading={isLoading} onClick={unsubscribeUser} />
			</div>
		</>
	)
}

export default CancelPlan
