import React, { useReducer, useEffect, useState, createContext, Dispatch } from 'react'
import './App.css'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import Home from './pages/home'
import Loading from './pages/loading'
import globalReducer from './utils/reducers/global-reducer'
import { GlobalState, GlobalStateAction, globalInitialState } from './utils/reducers/global-reducer'
import { AuthRequiredRoutes, InitialSubscribeRoute, NotAuthRoutes, SubscribedRoute } from './utils/custom-routes'
import AuthService from './services/auth'
import Login from './pages/login'
import ForgotPassword from './pages/forgot-password'
import Register from './pages/register'
import ProfileSetup from './pages/profile-setup'
import Snack from './components/core/snack'
import Subscribe from './pages/subscribe'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import asyncTimeout from './utils/asyncTimeout'
import PersonalSettings from './pages/personal-settings'
import AccountSettings from './pages/account-settings'
import PlanSettings from './pages/plan-settings'
import PaymentMethods from './pages/payment-methods'
import UpdatePassword from './pages/update-password'
import { PayPalScriptProvider } from '@paypal/react-paypal-js'
import DeleteAccount from './pages/delete-account'

export const GlobalContext = createContext<{ state: GlobalState; dispatch: Dispatch<GlobalStateAction> }>({
	state: globalInitialState,
	dispatch: () => null,
})

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY ?? '')

const options = {
	fonts: [
		{
			src: require('./assets/fonts/JosefinSans-Regular.ttf'),
			family: 'Josefin',
		},
	],
}

const paypalOptions = {
	'client-id': process.env.REACT_APP_PAYPAL_KEY ?? '',
	currency: 'AUD',
	vault: 'true',
	intent: 'subscription',
}

function App() {
	const [state, dispatch] = useReducer(globalReducer, globalInitialState)
	const [isLoadingUser, setIsLoadingUser] = useState(true)
	const token = localStorage.getItem('WHMToken')

	const getUserData = async () => {
		try {
			const [userData] = await Promise.all([AuthService.getMe(), asyncTimeout(2000)])

			dispatch({ type: 'setUser', data: userData?.user })
		} catch (error) {
			dispatch({ type: 'setUser', data: undefined })
			localStorage.removeItem('WHMToken')
		} finally {
			setIsLoadingUser(false)
		}
	}

	useEffect(() => {
		if (!token) {
			setIsLoadingUser(false)
			dispatch({ type: 'setUser', data: undefined })
			return
		}
		getUserData()
	}, [])

	if (isLoadingUser) return <Loading />

	return (
		<Elements stripe={stripePromise} options={options}>
			<PayPalScriptProvider options={paypalOptions}>
				<Router>
					<GlobalContext.Provider value={{ state, dispatch }}>
						<div className='max-w-md m-auto outline-[2px] relative'>
							<Snack
								isOpen={state.snack.isOpen}
								message={state.snack.message}
								severity={state.snack.severity}
								setIsOpen={(open) => dispatch({ type: 'setSnack', data: { ...state.snack, isOpen: open } })}
							/>

							<Routes>
								{/* Free Routes */}

								{/* Auth Routes */}
								<Route element={<AuthRequiredRoutes />}>
									<Route path='/' element={<Home />} />
									<Route path='/personal-settings' element={<PersonalSettings />} />
									<Route path='/account-settings' element={<AccountSettings />} />
									<Route path='/delete-account' element={<DeleteAccount />} />

									<Route path='/profile-setup' element={<ProfileSetup />} />
									<Route path='/update-password' element={<UpdatePassword />} />
								</Route>

								{/* Must Be Subscribed To Access */}
								<Route element={<SubscribedRoute />}>
									<Route path='/plan-settings' element={<PlanSettings />} />
									<Route path='/payment-methods' element={<PaymentMethods />} />
								</Route>

								{/* Must Not be Authed Routes */}
								<Route element={<NotAuthRoutes />}>
									<Route path='/login' element={<Login />} />
									<Route path='/register' element={<Register />} />
									<Route path='/forgot-password' element={<ForgotPassword />} />
								</Route>

								{/* Cannot go back to this route if actively subbed */}
								<Route element={<InitialSubscribeRoute />}>
									<Route path='/subscribe' element={<Subscribe />} />
								</Route>

								{/* Default Route */}
								<Route path='*' element={<Navigate to='/' replace />} />
							</Routes>

							{/* } */}
						</div>
					</GlobalContext.Provider>
				</Router>
			</PayPalScriptProvider>
		</Elements>
	)
}

export default App
