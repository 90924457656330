import { CircularProgress } from '@mui/material'
import { useFormik } from 'formik'
import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Camera from '../assets/icons/Camera'
import Facebook from '../assets/icons/Facebook'
import Instagram from '../assets/icons/Instagram'
import Minus from '../assets/icons/Minus'
import Tiktok from '../assets/icons/Tiktok'
import AuthService from '../services/auth'
import FileService from '../services/file'
import { PresignedUpload } from '../types/types'
import errorPopupParser from '../utils/error-popup-parser'
import refreshUserData from '../utils/refresh-user-data'
import useGlobalState from '../utils/use-global-state'
import { profileSetupSchema } from '../utils/validation-schemas'
import Button from './core/button'
import ValidationInput from './core/validation-input'

type Props = {
	pageType: 'SETUP' | 'EDIT'
}

function ProfileEditDetails({ pageType }: Props) {
	const { dispatch, state } = useGlobalState()
	const navigate = useNavigate()
	const auth = new AuthService()
	const file = new FileService()
	const inputFile = useRef<HTMLInputElement>(null)

	const [avatarURL, setAvatarURL] = useState<PresignedUpload>()
	const [avatarPreview, setAvatarPreview] = useState(state.user?.avatar ?? '')
	const [isAvatarUploading, setIsAvatarUploading] = useState(false)
	const [isLoading, setIsLoading] = useState(false)

	const { values, errors, touched, setFieldValue, handleSubmit } = useFormik({
		initialValues: {
			instagram: state.user?.instagram ? state.user.instagram.replaceAll('https://instagram.com/', '') : '',
			facebook: state.user?.facebook ? state.user.facebook.replaceAll('https://facebook.com/', '') : '',
			tiktok: state.user?.tiktok ? state.user.tiktok.replaceAll('https://tiktok.com/@', '') : '',
		},
		onSubmit: (values) => {
			updateUser()
		},
		validationSchema: profileSetupSchema,
	})

	const updateUser = async () => {
		setIsLoading(true)

		const updatedValues = {
			instagram: values.instagram ? `https://instagram.com/${values.instagram}` : '',
			facebook: values.facebook ? `https://facebook.com/${values.facebook}` : '',
			tiktok: values.tiktok ? `https://tiktok.com/@${values.tiktok}` : '',
			avatar: avatarURL?.fileName,
		}

		if (!avatarURL?.fileName) delete updatedValues.avatar

		try {
			await auth.updateOne(updatedValues, state.user!.id)

			await refreshUserData(dispatch)

			dispatch({
				type: 'setSnack',
				data: {
					isOpen: true,
					message: 'Your details have been saved!',
					severity: 'success',
				},
			})

			setTimeout(() => {
				navigate('/subscribe')
			}, 3000)
		} catch (error) {
			errorPopupParser(error, dispatch)
		} finally {
			setIsLoading(false)
		}
	}

	const handleAvatarUpdate = async (e: ChangeEvent<HTMLInputElement>) => {
		if (!e.target.files || e.target.files?.length === 0) return
		setAvatarURL(undefined)
		setAvatarPreview('')
		setIsAvatarUploading(true)
		try {
			const presignedURL = await file.getSingleUploadURL(e.target.files[0].name, 'avatar')

			await file.uploadWithPresignedURL(presignedURL.url, e.target.files[0])

			setAvatarURL(presignedURL)
		} catch (error) {
			errorPopupParser(error, dispatch)
			setAvatarURL(undefined)
		} finally {
			setIsAvatarUploading(false)
		}
	}

	const clickImagePicker = () => {
		if (!inputFile?.current) return
		inputFile.current.click()
	}

	const renderAvatar = () => {
		if (isAvatarUploading) return <CircularProgress size={'24px'} thickness={4.5} sx={{ color: '#FFF' }} />
		else if (avatarPreview) return <img src={avatarPreview} className='h-full w-full rounded-[50%]' />
		else return <Camera />
	}

	const getAvatar = async () => {
		if (!avatarURL?.fileName) return

		try {
			const avatarResult = await file.getSingleDownloadURL(avatarURL.fileName)
			setAvatarPreview(avatarResult)
		} catch (error) {
			errorPopupParser(error, dispatch)
		}
	}

	const removeAvatar = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		e.stopPropagation()
		setAvatarURL(undefined)
		setAvatarPreview('')
	}

	useEffect(() => {
		getAvatar()
	}, [avatarURL])

	return (
		<div>
			<input type='file' id='file' ref={inputFile} accept='image/*' style={{ display: 'none' }} onChange={(e) => handleAvatarUpdate(e)} />

			<div className='text-[20px] font-bold text-black-base mb-8 leading-[24px]'>Set Profile Picture</div>

			<div
				onClick={() => clickImagePicker()}
				className={`w-[140px] h-[140px] min-h-[140px] flex items-center justify-center mx-auto relative bg-purple-light rounded-[50%] mb-12 cursor-pointer ${
					!avatarPreview && 'hover:scale-105 hover:shadow-lg transition-all duration-300'
				} border-[3px] border-purple-base`}
			>
				{renderAvatar()}
				{avatarPreview && (
					<div onClick={(e) => removeAvatar(e)} className='absolute bottom-0 right-0 hover:scale-110 transition-all duration-300'>
						<Minus />
					</div>
				)}
			</div>

			<div className='text-[20px] font-bold text-black-base mb-2 leading-[24px]'>Set Your Socials</div>
			<div className='text-[18px] text-black-light leading-5 mb-6'>Link your accounts so we can follow your journey and cheer you on.</div>

			<div className='flex gap-4 w-full items-center'>
				<Instagram />
				<ValidationInput
					error={errors.instagram}
					touched={touched.instagram}
					value={values.instagram}
					onChange={(e) => setFieldValue('instagram', e.target.value)}
					placeholder='INSTAGRAM'
				/>
			</div>
			<a className='text-[12px] text-grey-base mb-4' href={`https://instagram.com/${values.instagram}`} target='_blank'>
				https://instagram.com/{values.instagram}
			</a>

			<div className='flex gap-4 w-full items-center mt-4'>
				<Facebook />
				<ValidationInput
					error={errors.facebook}
					touched={touched.facebook}
					value={values.facebook}
					onChange={(e) => setFieldValue('facebook', e.target.value)}
					placeholder='FACEBOOK'
				/>
			</div>
			<a className='text-[12px] text-grey-base mb-4' href={`https://facebook.com/${values.facebook}`} target='_blank'>
				https://facebook.com/{values.facebook}
			</a>

			<div className='flex gap-4 w-full items-center mt-4'>
				<Tiktok />
				<ValidationInput
					error={errors.tiktok}
					touched={touched.tiktok}
					value={values.tiktok}
					onChange={(e) => setFieldValue('tiktok', e.target.value)}
					placeholder='TIKTOK'
				/>
			</div>
			<a className='text-[12px] text-grey-base mb-4' href={`https://tiktok.com/@${values.tiktok}`} target='_blank'>
				https://tiktok.com/@{values.tiktok}
			</a>

			{pageType === 'SETUP' ? (
				<>
					<Button text='Next' onClick={handleSubmit} className='my-8' isLoading={isLoading} />

					<div className='text-[18px] text-black-light leading-5 mb-6 cursor-pointer text-center' onClick={() => navigate('/subscribe')}>
						Skip, I'll do this later.
					</div>

					<div className='py-4' />
				</>
			) : (
				<Button
					text='Save Settings'
					onClick={handleSubmit}
					className='my-8 bg-green-lime border-green-lime hover:bg-green-lime hover:border-green-lime'
					isLoading={isLoading}
				/>
			)}
		</div>
	)
}

export default ProfileEditDetails
