import Logo from '../assets/images/WHMLogo.jpg'
import ProfileEditDetails from '../components/profile-edit-details'

function ProfileSetup() {
    return (
        <div className='px-4 py-8 h-screen md:h-auto flex flex-col'>
            <div className='flex items-center justify-center relative mb-12 w-full'>
                <img src={Logo} className='h-5' alt='WHM Logo'/>
            </div>

            <div className='text-[30px] font-bold text-black-base mb-2 leading-[35px]'>Profile Set Up</div>
            <div className='text-[18px] text-black-light leading-5 mb-6'>Add a few personal touches to your profile!</div>

            <ProfileEditDetails pageType='SETUP' />

        </div>
    )
}

export default ProfileSetup
