import React, { useEffect, useState } from 'react'
import Arrow from '../assets/icons/Arrow'
import { PlanSettingsPages } from '../pages/plan-settings'
import { SubscriptionPlan } from '../types/types'
import Logo from '../assets/images/WHMLogo.jpg'
import useGlobalState from '../utils/use-global-state'
import moment from 'moment'
import PlanCard from './plan-card'
import PaymentMethodCard from './payment-method-card'
import StripeService from '../services/stripe'
import asyncTimeout from '../utils/asyncTimeout'
import errorPopupParser from '../utils/error-popup-parser'
import { PaymentMethod } from '@stripe/stripe-js/types/api'
import Button from './core/button'
import { useNavigate } from 'react-router-dom'
import refreshUserData from '../utils/refresh-user-data'

type Props = {
	newPlan: SubscriptionPlan
	setPage: (planSettingPage: PlanSettingsPages) => void
}

function ChangePlan({ newPlan, setPage }: Props) {
	const { state, dispatch } = useGlobalState()
	const { user } = state

	const stripe = new StripeService()
	const navigate = useNavigate()

	const [isLoading, setIsLoading] = useState(true)
	const [defaultPaymentMethod, setDefaultPaymentMethod] = useState<PaymentMethod>()

	const getPaymentMethods = async () => {
		setIsLoading(true)
		try {
			const [paymentMethodResult] = await Promise.all([stripe.getPaymentMethods(), asyncTimeout(500)])
			setDefaultPaymentMethod(paymentMethodResult.defaultPaymentMethod)
		} catch (error) {
			errorPopupParser(error, dispatch)
		} finally {
			setIsLoading(false)
		}
	}

	const changeUserPlan = async () => {
		setIsLoading(true)

		try {
			await Promise.all([stripe.createSubscription(newPlan.stripePriceId, user!.id), asyncTimeout(800)])

			dispatch({
				type: 'setSnack',
				data: {
					isOpen: true,
					message: 'Your plan has been updated',
					severity: 'success',
				},
			})

			setTimeout(() => {
				refreshUserData(dispatch)
				setPage('DEFAULT')
			}, 2000)
		} catch (error) {
			errorPopupParser(error, dispatch)
		} finally {
			setIsLoading(false)
		}
	}

	const renderDefaultMethod = () => {
		if (isLoading)
			return (
				<div className='flex items-center gap-4 border-2 p-3 mb-4 rounded-lg bg-grey-light h-[66px] border-grey-light select-none animate-pulse' />
			)

		if (!defaultPaymentMethod) return <div>No payment method found</div>

		return <PaymentMethodCard isDefault paymentMethod={defaultPaymentMethod} />
	}

	useEffect(() => {
		getPaymentMethods()
	}, [])

	return (
		<>
			<div className='flex items-center justify-center relative mb-8 w-full'>
				<Arrow className='absolute left-0' onClick={() => setPage('DEFAULT')} />
				<img src={Logo} className='h-5' alt='WHM Logo' />
			</div>

			<div className='font-bold text-[30px] text-black-base'>Upgrade time!</div>

			<div className='leading-5 text-[18px] text-black-base mb-4 opacity-80'>
				By upgrading your plan you will pay less for the same features and experience on WHM!
			</div>

			<div className='p-2 border rounded-lg border-grey-light leading-5  text-black-base mb-4 opacity-60'>
				The new selected plan will come into effect on the next billing period of{' '}
				<span className='font-bold'>{moment.unix(user!.subscriptionDetails!.current_period_end).format('DD-MM-yyyy')}</span>
			</div>

			<div className='font-bold text-black-base text-[18px] mb-2'>Your New Plan</div>

			<PlanCard isSelected setPlan={() => null} plan={newPlan} />
			<div className='mx-auto text-[10px] text-black-base text-center mb-6'>
				This amount will automatically be direct debited from your account.
			</div>

			<div className='font-bold text-black-base text-[18px] mb-1'>Payment Method</div>

			<div className='leading-5 text-[18px] text-black-base mb-4 opacity-80'>
				The currently selected default payment method will be used to make these changes.
			</div>

			{renderDefaultMethod()}

			<div className='grow flex items-end'>
				<Button text='Change Plan' onClick={changeUserPlan} isLoading={isLoading} />
			</div>
		</>
	)
}

export default ChangePlan
