import { useNavigate } from 'react-router-dom'
import Arrow from '../assets/icons/Arrow'
import Button from '../components/core/button'
import { useState } from 'react'
import DeleteAccountModal from '../components/delete-account-modal'

const DeleteAccount = () => {
	const navigate = useNavigate()
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

	return (
		<div className='px-4 py-8 h-screen md:h-auto flex flex-col'>
			<DeleteAccountModal isOpen={isDeleteModalOpen} setIsOpen={setIsDeleteModalOpen} />

			<div className='flex items-center justify-center relative mb-8 w-full'>
				<Arrow className='absolute left-0' onClick={() => navigate('/login')} />
				<div className='text-[20px] font-bold text-black-base leading-[24px]'>Delete Account</div>
			</div>

			<div className='border-grey-base88 border-b m-6 w-[80%] mx-auto' />

			<div className='text-[20px] font-bold text-black-base mb-2 leading-[24px]'>Delete Your WHM Account</div>

			<p className='text-[18px] text-black-light leading-5 mb-2'>
				By permanently deleting your account from the Womens Health Movement App, you will lose access to the platform, all your information
				regarding your account and any unused portion of your subscription.
			</p>

			<Button
				onClick={() => setIsDeleteModalOpen(true)}
				text='Delete Account'
				className='bg-white hover:bg-white border-error-base text-error-base hover:border-error-base mb-4'
			/>

			<div className='py-2' />
		</div>
	)
}

export default DeleteAccount
