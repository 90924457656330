import { SubscriptionPlan } from '../types/types'
import ArrowLong from '../assets/icons/ArrowLong'

type Props = {
	plan: SubscriptionPlan
	setPlan: (plan: SubscriptionPlan) => void
	isSelected: boolean
	isAllPlansView?: boolean
}

function PlanCard({ plan, isSelected, setPlan, isAllPlansView }: Props) {
	return (
		<div
			onClick={() => setPlan(plan)}
			className={`${
				isSelected ? 'bg-gradient-to-br from-[#D6A156] to-[#5E257D] rounded-lg border border-[#333333] border-opacity-20 ' : 'bg-white'
			} transition-all cursor-pointer duration-300 px-3 py-4 rounded-lg mb-3 relative border border-[#33333333]`}
		>
			<div className='flex justify-between w-full mb-1 items-center'>
				<p className={`${isSelected ? 'text-white' : 'text-purple-base'} font-semibold text-[28px] uppercase `}>
					{plan.subscriptionPeriodText}
				</p>
				{plan.saveText && (
					<p
						className={`${
							isSelected ? ' border-white' : 'bg-gradient-to-br from-[#D6A156] to-[#5E257D]'
						} text-[12px] text-white px-2 py-1 rounded-lg border `}
					>
						{plan.saveText}
					</p>
				)}
			</div>

			<div className='flex justify-between items-center mb-4'>
				<h1 className={`${isSelected ? 'text-white' : 'text-purple-base'} font-light text-[20px] `}>${plan.price}</h1>
				<p className={`${isSelected ? 'text-white' : 'text-purple-base'} text-[12px] `}>{plan?.billingPeriod}</p>
			</div>

			{isAllPlansView && (
				<p
					className={`${
						isSelected ? 'text-white border-white  ' : 'text-purple-base border-purple-base '
					} border px-3 py-1 text-center rounded-lg  font-semibold text-[10px] flex justify-between items-center `}
				>
					Start Your 7 Day Free Trial
					<ArrowLong className='scale-50' />
				</p>
			)}
		</div>
	)
}

export default PlanCard
