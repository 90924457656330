import React, { useState } from 'react'
import Arrow from '../assets/icons/Arrow'
import StripeService from '../services/stripe'
import useGlobalState from '../utils/use-global-state'
import Button from './core/button'
import ModalWrapper from './core/modal-wrapper'
import { PaymentMethod } from '@stripe/stripe-js'
import PaymentMethodCard from './payment-method-card'
import asyncTimeout from '../utils/asyncTimeout'
import errorPopupParser from '../utils/error-popup-parser'

type Props = {
	paymentMethod: PaymentMethod | undefined
	closeModal: () => void
	getPaymentMethods: () => Promise<void>
}

function PaymentMethodOptionsModal({ paymentMethod, closeModal, getPaymentMethods }: Props) {
	const { dispatch } = useGlobalState()
	const stripe = new StripeService()

	const [isLoading, setIsLoading] = useState(false)

	const makeDefaultMethod = async () => {
		setIsLoading(true)
		try {
			await Promise.all([stripe.setDefaultPaymentMethod(paymentMethod!.id), asyncTimeout(800)])
			await getPaymentMethods()

			dispatch({
				type: 'setSnack',
				data: {
					message: 'Default payment method successfully updated',
					severity: 'success',
					isOpen: true,
				},
			})
			closeModal()
		} catch (error) {
			errorPopupParser(error, dispatch)
		} finally {
			setIsLoading(false)
		}
	}

	const deletePaymentMethod = async () => {
		setIsLoading(true)
		try {
			await Promise.all([stripe.deletePaymentMethod(paymentMethod!.id), asyncTimeout(800)])
			await getPaymentMethods()

			dispatch({
				type: 'setSnack',
				data: {
					message: 'Your payment method has been deleted',
					severity: 'success',
					isOpen: true,
				},
			})
			closeModal()
		} catch (error) {
			errorPopupParser(error, dispatch)
		} finally {
			setIsLoading(false)
		}
	}

	return (
		<ModalWrapper isOpen={paymentMethod !== undefined} setIsOpen={closeModal}>
			<div className='absolute bottom-0 bg-white w-full pb-16' onClick={(e) => e.stopPropagation()}>
				<div className='flex items-center p-3 border-b border-grey-base88 font-bold mb-4'>
					<Arrow onClick={() => closeModal()} />
					<div className='mx-auto text-[20px] text-black-base'>Payment Method Options</div>
				</div>

				<div className='mx-3 mb-4'>{paymentMethod && <PaymentMethodCard paymentMethod={paymentMethod} />}</div>

				<div className='mx-3'>
					<div className='text-[18px] font-bold text-black-base opacity-80'>Make Default Method</div>

					<div className='text-[18px] text-black-base opacity-80 leading-5 mb-2'>
						By making this your default payment method subscription payments, and WHM purchases will by default be made to this method.
					</div>

					<Button text='Make Default Method' isLoading={isLoading} onClick={makeDefaultMethod} className='mb-8' />
				</div>

				<div className='mx-3 mb-8'>
					<div className='text-[18px] font-bold text-black-base opacity-80'>Delete Payment Method</div>

					<div className='text-[18px] text-black-base opacity-80 leading-5 mb-2'>
						By removing this payment method it will permanently erase these details.
					</div>
					<Button
						text='Delete Method'
						isLoading={isLoading}
						onClick={deletePaymentMethod}
						className='text-white bg-error-base hover:bg-error-base border-white hover:border-white hover:text-white'
					/>
				</div>
			</div>
		</ModalWrapper>
	)
}

export default PaymentMethodOptionsModal
