import axios from 'axios'
import Instance from '../utils/axios'

const networkErrorMessage = 'There was an error with your connection, please try again'

class PaypalService {
	createSubscription = async (subscriptionId: string, userId: string): Promise<number> => {
		try {
			const result = await Instance.post(`paypal/users/${userId}/subscriptions`, {
				subscriptionId,
			})

			return result.status
		} catch (error) {
			if (error && axios.isAxiosError(error)) {
				if (error?.code === 'ERR_NETWORK' || error?.code === 'ECONNABORTED') throw Error(networkErrorMessage)
			}

			throw Error('Could not create subscription with paypal, please try again')
		}
	}
}

export default PaypalService
