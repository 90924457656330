import AuthService from '../services/auth'
import errorPopupParser from './error-popup-parser'
import { GlobalStateAction } from './reducers/global-reducer'

const refreshUserData = async (dispatch: (options: GlobalStateAction) => void) => {
	try {
		const userData = await AuthService.getMe()
		dispatch({ type: 'setUser', data: userData?.user })
	} catch (error) {
		errorPopupParser(error, dispatch)
	}
}

export default refreshUserData
