import React, { useState } from 'react'
import { User } from '../types/types'
import useGlobalState from '../utils/use-global-state'
import Logo from '../assets/images/WHMLogo.jpg'
import Button from '../components/core/button'
import Logout from '../assets/icons/Logout'
import Arrow from '../assets/icons/Arrow'
import { useNavigate } from 'react-router-dom'
import AppStore from '../assets/icons/AppStore'

type HomePageLink = {
	name: string
	route: string
}

function Home() {
	const { state, dispatch } = useGlobalState()
	const user = state.user as User

	const navigate = useNavigate()

	const [avatar, setAvatar] = useState(state.user?.avatar ?? undefined)

	const homeLinksUnsubbed: HomePageLink[] = [
		{
			name: 'Personal Settings',
			route: '/personal-settings',
		},
		{
			name: 'Account Settings',
			route: '/account-settings',
		},
		{
			name: 'Update Password',
			route: '/update-password',
		},
		{
			name: 'Delete Account',
			route: '/delete-account',
		},
	]

	const homeLinks: HomePageLink[] = [
		{
			name: 'Personal Settings',
			route: '/personal-settings',
		},
		{
			name: 'Account Settings',
			route: '/account-settings',
		},
		{
			name: 'Plan Settings',
			route: '/plan-settings',
		},
		{
			name: 'Payment Methods',
			route: '/payment-methods',
		},
		{
			name: 'Update Password',
			route: '/update-password',
		},
		{
			name: 'Delete Account',
			route: '/delete-account',
		},
	]

	const renderLinks = (): JSX.Element[] => {
		if (user.userSubscriptionInformation?.subscriptionId) {
			let filteredHomeLinks = [...homeLinks]
			if (user.userSubscriptionInformation.type === 'RevenueCat') {
				filteredHomeLinks = homeLinks.filter((link) => link.name !== 'Payment Methods')
			}

			return filteredHomeLinks.map((link, index) => (
				<div key={index}>
					<div className='flex justify-between items-center cursor-pointer' onClick={() => navigate(link.route)}>
						<div className='text-purple-base text-[22px] font-semibold'>{link.name}</div>
						<Arrow alignment='right' />
					</div>
					<div className='border-grey-base88 border-b-[2px] m-6 w-[80%] mx-auto' />
				</div>
			))
		} else
			return homeLinksUnsubbed.map((link, index) => (
				<div key={index}>
					<div className='flex justify-between items-center cursor-pointer' onClick={() => navigate(link.route)}>
						<div className='text-purple-base text-[22px] font-semibold'>{link.name}</div>
						<Arrow alignment='right' />
					</div>
					<div className='border-grey-base88 border-b-[2px] m-6 w-[80%] mx-auto' />
				</div>
			))
	}

	const renderSubscribeBlock = (): JSX.Element => {
		return (
			<div className='bg-gold-light p-3 rounded-lg'>
				<div className='text-gold-base text-[22px] font-semibold'>Get The Full Experience</div>
				<div className='text-black-light opacity-80 text-[18px] mb-2'>
					We’re here to hold space for the whole of you, not just one aspect of your life.
				</div>
				<div className='text-black-light opacity-80 text-[18px]'>
					Subscribe to access our bespoke Programs and Courses designed for Women, by Women.
				</div>
				<Button text='Subscribe Now!' onClick={() => navigate('subscribe')} className='text-[18px]' />
			</div>
		)
	}

	const logoutUser = () => {
		dispatch({ type: 'setUser', data: undefined })
		localStorage.removeItem('WHMToken')
	}

	const renderUserSubscriptionStatus = () => {
		if (user.userSubscriptionInformation?.subscriptionId)
			return <div className='bg-purple-base px-2 py-[2px] text-white rounded-xl text-[12px] w-fit'>WHM Premium</div>
		return <div className='bg-grey-base px-2 py-[2px] text-white rounded-xl text-[12px] w-fit'>Not Subscribed</div>
	}

	return (
		<div className='p-4 h-screen md:h-auto flex flex-col'>
			<div className='flex items-center justify-center relative mb-12 w-full'>
				<img src={Logo} className='h-5' alt='WHM Logo' />
			</div>

			<div className='border-grey-base88 border-[1px] p-2 rounded-md flex gap-2 items-center mb-2'>
				<div className='rounded-[50%] border-grey-base88 border-[1px] h-[60px] w-[60px] bg-grey-light'>
					<img src={avatar} className='h-full w-full rounded-[50%]' onError={() => setAvatar(undefined)} />
				</div>

				<div>
					<div className='text-purple-base text-[24px] font-bold leading-8'>{user.firstName + ' ' + user.lastName}</div>
					{renderUserSubscriptionStatus()}
				</div>

				<Logout className='ml-auto' onClick={() => logoutUser()} />
			</div>

			<div className='mb-6'>{!user.userSubscriptionInformation?.subscriptionId && renderSubscribeBlock()}</div>

			{renderLinks()}

			<div className='grid grid-cols-2 items-center mx-auto'>
				<a href='https://apps.apple.com/us/app/womens-health-movement/id1629535747' target='_blank'>
					<AppStore className='cursor-pointer' />
				</a>
				<a
					href='https://play.google.com/store/apps/details?id=com.womenshealthmovement&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
					target='_blank'
				>
					<img
						alt='Get it on Google Play'
						src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'
						className='w-[155px]'
					/>
				</a>
			</div>
		</div>
	)
}

export default Home
