import React, { HTMLInputTypeAttribute, useState } from 'react'
import Arrow from '../assets/icons/Arrow'
import { useFormik } from 'formik'
import { changePasswordSchema } from '../utils/validation-schemas'
import { useNavigate } from 'react-router'
import Logo from '../assets/images/WHMLogo.jpg'
import ValidationInput from './core/validation-input'
import ShowPassword from '../assets/icons/ShowPassword'
import Button from './core/button'

type Props = {
    onChangeComplete: (password: string, confirmPassword: string) => void
    onClickBack: (...args: any) => void
}

function ChangePassword({ onChangeComplete, onClickBack }: Props) {

    const navigate = useNavigate()

    const [passwordInputType, setPasswordInputType] = useState<HTMLInputTypeAttribute>('password')
    const [confirmPasswordInputType, setConfirmPasswordInputType] = useState<HTMLInputTypeAttribute>('password')
    const [isLoading, setIsLoading] = useState(false)

    const { values, errors, setFieldValue, handleSubmit, touched } = useFormik({
        initialValues: {
            password: '',
            confirmPassword: '',
        },
        onSubmit: values => {
            onChangeComplete(values.password, values.confirmPassword)
        },
        validationSchema: changePasswordSchema
    })

    return (
        <div className='px-4 py-8 h-screen md:h-auto flex flex-col'>
            <div className='flex items-center justify-center relative mb-12 w-full'>
                <Arrow className='absolute left-0' onClick={onClickBack} />
                <img src={Logo} className='h-5' alt='WHM Logo' />
            </div>
            <div className='text-[30px] font-bold text-black-base mb-2 leading-[35px]'>New Password</div>
            <div className='text-[18px] text-black-light mb-4 leading-5'>Create a new WHM account password.</div>

            <ValidationInput inputType={passwordInputType} error={errors.password} touched={touched.password} value={values.password} onChange={(e) => setFieldValue('password', e.target.value)} placeholder='PASSWORD' className='mb-2'
                icon={<ShowPassword onClick={() => setPasswordInputType(passwordInputType === 'password' ? 'text' : 'password')} />} />

            <ValidationInput inputType={confirmPasswordInputType} error={errors.confirmPassword} touched={touched.confirmPassword} value={values.confirmPassword} onChange={(e) => setFieldValue('confirmPassword', e.target.value)} placeholder='CONFIRM PASSWORD' className='mb-2'
                icon={<ShowPassword onClick={() => setConfirmPasswordInputType(confirmPasswordInputType === 'password' ? 'text' : 'password')} />} />

            <div className='grow flex items-end'>
                <Button text='Next' onClick={handleSubmit} isLoading={isLoading} />
            </div>
        </div>
    )
}

export default ChangePassword