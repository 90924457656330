

import React from 'react'
import { IconBaseProps } from '../../types/types'

interface Props extends IconBaseProps {

}

function Facebook({ className, onClick }: Props) {

  return (
    <div className={`${onClick ? 'cursor-pointer hover:scale-110 hover:rotate-3 transition-all duration-300 ' : ' '}` + className} onClick={onClick}>
      <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
        <g id="Group_400" data-name="Group 400" transform="translate(-499.219 -239.219)">
          <g id="Rectangle_567" data-name="Rectangle 567" transform="translate(499.219 239.219)" fill="rgba(214,161,86,0.3)" stroke="#5e257d" strokeWidth="2">
            <rect width="34" height="34" rx="10" stroke="none" />
            <rect x="1" y="1" width="32" height="32" rx="9" fill="none" />
          </g>
          <path id="Icon_awesome-facebook-f" data-name="Icon awesome-facebook-f" d="M15,15.046l.743-4.841H11.095V7.064a2.421,2.421,0,0,1,2.729-2.615h2.112V.327A25.752,25.752,0,0,0,12.187,0C8.362,0,5.862,2.319,5.862,6.516v3.689H1.609v4.841H5.862v11.7h5.233v-11.7Z" transform="translate(507.446 245.689)" fill="#5e257d" />
        </g>
      </svg>



    </div>
  )
}

export default Facebook