import { Navigate, Outlet } from 'react-router-dom'
import useGlobalState from './use-global-state'

const useAuth = () => {
	const { state } = useGlobalState()
	const { user } = state

	if (user) return true
	return false
}

const useSubbed = () => {
	const { state } = useGlobalState()
	const { user } = state

	if (user && user.userSubscriptionInformation?.subscriptionId) return true
	return false
}

export const AuthRequiredRoutes = () => {
	const isAuth = useAuth()
	return isAuth ? <Outlet /> : <Navigate to='/login' replace />
}

export const NotAuthRoutes = () => {
	const isAuth = useAuth()
	return isAuth ? <Navigate to='/' replace /> : <Outlet />
}

// can only go to this route if the user is not subscribed at all
export const InitialSubscribeRoute = () => {
	const isSubbed = useSubbed()
	const isAuth = useAuth()
	if (!isAuth) return <Navigate to='/login' replace />
	return isSubbed ? <Navigate to='/' replace /> : <Outlet />
}

// can only go to this route if the user is subscribed
export const SubscribedRoute = () => {
	const isSubbed = useSubbed()
	const isAuth = useAuth()
	if (!isAuth) return <Navigate to='/login' replace />
	return !isSubbed ? <Navigate to='/subscribe' replace /> : <Outlet />
}
