import React from 'react'
import { Snack } from '../../types/types'
import { Snackbar as MUISnack } from '@mui/material'

interface Props extends Snack {
    setIsOpen: (isOpen: boolean) => void
}

function Snackbar({ isOpen, severity, message, setIsOpen }: Props) {

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return
        }

        setIsOpen(false)
    }

    if (!message) return null

    return (
        <MUISnack open={isOpen} 
        autoHideDuration={4000} 
        onClose={handleClose} 
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }} 
        onClick={handleClose}>
            <div className={`w-full border-[1px] border-grey-base88 rounded-lg text-[16px] text-white p-3 ${severity === 'success' ? 'bg-[#2BB32B]' : 'bg-error-base'}`}>
                {message}
            </div>
        </MUISnack>
    )
}

export default Snackbar