import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Arrow from '../assets/icons/Arrow'
import DateValidationInput from '../components/core/date-validation-input'
import ValidationInput from '../components/core/validation-input'
import { useFormik } from 'formik'
import { editAccountSchema } from '../utils/validation-schemas'
import AutocompleteInput from '../components/core/autocomplete-input'
import Button from '../components/core/button'
import Help from '../assets/icons/Help'
import useGlobalState from '../utils/use-global-state'
import refreshUserData from '../utils/refresh-user-data'
import errorPopupParser from '../utils/error-popup-parser'
import AuthService from '../services/auth'
import ChangeMobile from '../components/change-mobile'
import { PartialUserUpdate, User } from '../types/types'
import patchParseNewValues from '../utils/patch-parse-new-values'

type AccountSettingPages = 'DEFAULT' | 'CHANGE_MOBILE'

function AccountSettings() {
	const navigate = useNavigate()
	const auth = new AuthService()

	const { state, dispatch } = useGlobalState()
	const { user } = state

	const [page, setPage] = useState<AccountSettingPages>('DEFAULT')
	const [isLoading, setIsLoading] = useState(false)

	if (!user) {
		refreshUserData(dispatch)
	}

	// flesh out user type with all of its actual returned values, user stripe user mobile user address etc

	const { values, errors, setFieldValue, handleSubmit, touched } = useFormik({
		initialValues: {
			firstName: user?.firstName ?? '',
			lastName: user?.lastName ?? '',
			birthday: user?.birthday ?? '',
			email: user?.email ?? '',
			userAddress: {
				fullAddress: user?.userAddress?.fullAddress ?? '',
				streetName: user?.userAddress?.streetName ?? '',
				streetNumber: user?.userAddress?.streetNumber ?? '',
				googlePlaceId: user?.userAddress?.googlePlaceId ?? '',
				lng: user?.userAddress?.lng ?? 0,
				lat: user?.userAddress?.lat ?? 0,
				state: user?.userAddress?.state ?? '',
				country: user?.userAddress?.country ?? '',
				city: user?.userAddress?.city ?? '',
				suburb: user?.userAddress?.suburb ?? '',
				postcode: user?.userAddress?.postcode ?? '',
			},
		},
		onSubmit: (values) => {
			updateUser()
		},
		validationSchema: editAccountSchema,
	})

	const updateUser = async () => {
		setIsLoading(true)
		if (!user) {
			navigate('/login')
			return
		}

		const { userAddress } = values

		const formattedValues = {
			...values,
			userAddress: {
				fullAddress: userAddress.fullAddress,
				streetName: userAddress.streetName,
				streetNumber: userAddress.streetNumber,
				googlePlaceId: userAddress.googlePlaceId,
				lng: userAddress.lng,
				lat: userAddress.lat,
				state: userAddress.state,
				country: userAddress.country,
				city: userAddress.city ?? '',
				suburb: userAddress.suburb ?? '',
				postcode: userAddress.postcode,
			},
		}

		const newValues: PartialUserUpdate = patchParseNewValues<User>(user, formattedValues)

		try {
			await auth.updateOne(newValues, state.user!.id)

			await refreshUserData(dispatch)

			dispatch({
				type: 'setSnack',
				data: {
					isOpen: true,
					severity: 'success',
					message: 'Details updated!',
				},
			})
		} catch (error) {
			errorPopupParser(error, dispatch)
		} finally {
			setIsLoading(false)
		}
		setIsLoading(false)
	}

	const updateDOB = (newDate: string | Date) => {
		if (newDate === '') {
			setFieldValue('birthday', newDate)
			return
		}
		setFieldValue('birthday', newDate)
	}

	if (page === 'CHANGE_MOBILE') return <ChangeMobile goBackToSettings={() => setPage('DEFAULT')} />

	return (
		<div className='px-4 py-8 h-screen md:h-auto flex flex-col'>
			<div className='flex items-center justify-center relative mb-8 w-full'>
				<Arrow className='absolute left-0' onClick={() => navigate('/login')} />
				<div className='text-[20px] font-bold text-black-base leading-[24px]'>Account Settings</div>
			</div>

			<h1 className='text-[20px] font-bold text-black-base leading-[28px]'>Mobile Number</h1>
			<p className='text-[18px] leading-[20px] mb-8'>Update the mobile number associated with your account.</p>

			<div className='flex justify-between items-center cursor-pointer' onClick={() => setPage('CHANGE_MOBILE')}>
				<div className='text-purple-base text-[22px] font-semibold'>Change Mobile Number</div>
				<Arrow alignment='right' />
			</div>
			<div className='border-grey-base88 border-b-[2px] m-6 w-[80%] mx-auto' />

			<h1 className='text-[20px] font-bold text-black-base leading-[28px]'>Account Details</h1>
			<p className='text-[18px] leading-[20px] mb-2'>Update your personal account details.</p>

			<ValidationInput
				error={errors.firstName}
				touched={touched.firstName}
				value={values.firstName}
				onChange={(e) => setFieldValue('firstName', e.target.value)}
				placeholder='FIRST NAME'
				className='mb-2'
			/>

			<ValidationInput
				error={errors.lastName}
				touched={touched.lastName}
				value={values.lastName}
				onChange={(e) => setFieldValue('lastName', e.target.value)}
				placeholder='LAST NAME'
				className='mb-2'
			/>
			<DateValidationInput
				error={errors.birthday}
				touched={touched.birthday}
				value={values.birthday}
				onChange={(newDate) => updateDOB(newDate)}
				placeholder='birthday'
				className='mb-2'
			/>

			<ValidationInput
				error={errors.email}
				touched={touched.email}
				value={values.email}
				onChange={(e) => setFieldValue('email', e.target.value)}
				placeholder='EMAIL'
				className='mb-2'
			/>

			<div className='border-grey-base88 border-b m-6 w-[80%] mx-auto' />

			<div className='text-[20px] font-bold text-black-base mb-2 leading-[24px]'>Location</div>
			<div className='text-[18px] text-black-light leading-5 mb-2'>
				We use your location to help the WHM team create better experience, programs and courses for our users.
			</div>

			<AutocompleteInput
				initialQuery={values.userAddress.fullAddress}
				errors={errors.userAddress}
				touched={touched.userAddress}
				setAddress={(address) => setFieldValue('userAddress', address)}
			/>
			<div className='border-grey-base88 border-b m-6 w-[80%] mx-auto' />

			<div className='bg-[#5E257D1A] p-3 rounded-lg'>
				<div className='text-black text-[22px] font-semibold flex justify-between'>
					Needing to change your plan?
					<Help />
				</div>
				<div className='text-black-light opacity-80 text-[18px] mb-2 leading-6'>
					If you need to update or cancel your Women's Health Movement Subscription, you can do this via the ‘Plan Settings’ area.
				</div>
				<Button
					text='Go To Plan Settings'
					onClick={() => navigate('/plan-settings')}
					className='text-[18px] bg-purple-base hover:bg-purple-base border-purple-base hover:border-purple-base'
				/>
			</div>

			<div className='border-grey-base88 border-b m-6 w-[80%] mx-auto' />

			<Button
				text='Save Settings'
				onClick={handleSubmit}
				className='mb-4 bg-green-lime border-green-lime hover:bg-green-lime hover:border-green-lime'
				isLoading={isLoading}
			/>
			<div className='py-2' />
		</div>
	)
}

export default AccountSettings
