import axios, { AxiosError, AxiosResponse } from 'axios'
import { PaymentMethodReturn, SendVerificationResult, User, UserLoggedIn, VerificationCodeOperation } from '../types/types'
import Instance from '../utils/axios'
import Crudable from './crudable'

const networkErrorMessage = 'There was an error with your connection, please try again'

class StripeService {
	addPaymentMethod = async (paymentMethodId: string): Promise<number> => {
		try {
			const result = await Instance.post('stripe/payment-methods', {
				paymentMethodId,
			})

			return result.status
		} catch (error) {
			if (error && axios.isAxiosError(error)) {
				if (error?.code === 'ERR_NETWORK' || error?.code === 'ECONNABORTED') throw Error(networkErrorMessage)
			}

			throw Error('Invalid payment method')
		}
	}

	createSubscription = async (priceId: string, userId: string): Promise<number> => {
		try {
			const result = await Instance.post(`stripe/users/${userId}/subscriptions`, {
				priceId,
			})

			console.log(result)

			return result.status
		} catch (error) {
			if (error && axios.isAxiosError(error)) {
				if (error?.code === 'ERR_NETWORK' || error?.code === 'ECONNABORTED') throw Error(networkErrorMessage)
			}

			throw Error('Could not create subscription, please try again')
		}
	}

	getPaymentMethods = async (): Promise<PaymentMethodReturn> => {
		try {
			const result = await Instance.get('stripe/payment-methods')

			return result.data
		} catch (error) {
			if (error && axios.isAxiosError(error)) {
				if (error?.code === 'ERR_NETWORK' || error?.code === 'ECONNABORTED') throw Error(networkErrorMessage)
			}

			throw Error('Could not get payment methods')
		}
	}

	setDefaultPaymentMethod = async (paymentMethodId: string): Promise<boolean> => {
		try {
			const result = await Instance.put(`stripe/payment-methods/${paymentMethodId}/setDefault`)

			return true
		} catch (error) {
			if (error && axios.isAxiosError(error)) {
				if (error?.code === 'ERR_NETWORK' || error?.code === 'ECONNABORTED') throw Error(networkErrorMessage)
			}

			throw Error('Could not set default payment method')
		}
	}

	deletePaymentMethod = async (paymentMethodId: string): Promise<boolean> => {
		try {
			const result = await Instance.delete(`stripe/payment-methods/${paymentMethodId}`)

			return true
		} catch (error) {
			if (error && axios.isAxiosError(error)) {
				if (error?.code === 'ERR_NETWORK' || error?.code === 'ECONNABORTED') throw Error(networkErrorMessage)
			}

			throw Error('Could not delete payment method')
		}
	}

	deleteSubscription = async (code: string, userId: string): Promise<boolean> => {
		try {
			const result = await Instance.delete(`stripe/users/${userId}/subscriptions`, {
				data: {
					code,
				},
			})

			return true
		} catch (error) {
			if (error && axios.isAxiosError(error)) {
				if (error?.code === 'ERR_NETWORK' || error?.code === 'ECONNABORTED') throw Error(networkErrorMessage)
			}

			throw Error('Could not delete subscription')
		}
	}
}

export default StripeService
