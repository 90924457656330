import React, { useState, useEffect, useRef, KeyboardEvent } from 'react'
import TextInput from './core/text-input'

type Props = {
    length: number
    handleCodeComplete: (code: string) => void
    disabled?: boolean
}

function VerificationCodeInput({ length, handleCodeComplete, disabled }: Props) {

    const [code, setCode] = useState<string[]>([...Array(length)].map(() => ''))
    const inputs = useRef<HTMLInputElement[]>([])

    const processInput = (text: string, slot: number) => {
        const num = text
        if (/[^0-9]/.test(num)) return
        const newCode = [...code]
        newCode[slot] = num
        setCode(newCode)
        if (slot !== length - 1) {
            inputs.current[slot + 1].focus()
        }
    }

    const onKeyUp = (e: KeyboardEvent, slot: number) => {
        if (e.key === 'Backspace' && !code[slot] && slot !== 0) {
            // tried to fix double delete at the end
            // if (slot === length - 1 && code[slot] !== '') return
            const newCode = [...code]
            newCode[slot - 1] = ""
            setCode(newCode)
            inputs.current[slot - 1].focus()
        }
    }

    const checkCode = (): boolean => {
        let result = true
        for (let i = 0; i < code.length; i++) {
            if (code[i] === '') {
                result = false
                break
            }
        }
        return result
    }

    useEffect(() => {
        const handlePaste = (e: any) => {
            const pasteData = e.clipboardData?.getData('text').trim()
            if (isNaN(pasteData) || pasteData.length !== length) return
            setCode(pasteData.split(''))
        }

        window.addEventListener('paste', handlePaste)

        return () => {
            window.removeEventListener('paste', handlePaste)
        }

    }, [])

    useEffect(() => {
        if (checkCode()) handleCodeComplete(code.join().replaceAll(',', ''))
    }, [code])

    return (
        <div className='flex gap-1'>
            {code.map((num, index) => {
                return <TextInput key={index} value={num} 
                onChange={text => processInput(text, index)}
                reference={reference => inputs.current.push(reference!)}
                onKeyUp={e => onKeyUp(e, index)}
                autoFocus={!code[0].length && index === 0}
                className='mb-4 text-center'
                onPaste={(e) => e.preventDefault()}
                />                
            })}
        </div>
    )
}

export default VerificationCodeInput
