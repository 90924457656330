import { useFormik } from 'formik'
import { HTMLInputTypeAttribute, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Arrow from '../assets/icons/Arrow'
import ShowPassword from '../assets/icons/ShowPassword'
import Logo from '../assets/images/WHMLogo.jpg'
import Button from '../components/core/button'
import DateValidationInput from '../components/core/date-validation-input'
import ValidationInput from '../components/core/validation-input'
import AuthService from '../services/auth'
import asyncTimeout from '../utils/asyncTimeout'
import useGlobalState from '../utils/use-global-state'
import { signupSchema } from '../utils/validation-schemas'

type RegisterProps = {
	code: string
	token: string
}

function Register() {
	function isRegisterProps(state: any): state is RegisterProps {
		if (!state) return false
		return 'code' in state && 'token' in state
	}

	const { state } = useLocation()
	const navigate = useNavigate()
	const { dispatch } = useGlobalState()
	const auth = new AuthService()

	const [passwordInputType, setPasswordInputType] = useState<HTMLInputTypeAttribute>('password')
	const [confirmPasswordInputType, setConfirmPasswordInputType] = useState<HTMLInputTypeAttribute>('password')
	const [isLoading, setIsLoading] = useState(false)

	const { values, errors, setFieldValue, handleSubmit, touched } = useFormik({
		initialValues: {
			firstName: '',
			lastName: '',
			birthday: '',
			email: '',
			password: '',
			confirmPassword: '',
			code: '',
			token: '',
		},
		onSubmit: (values) => {
			registerUser()
		},
		validationSchema: signupSchema,
	})

	const updateDOB = (newDate: string | Date) => {
		if (newDate === '') {
			setFieldValue('birthday', newDate)
			return
		}
		setFieldValue('birthday', newDate)
	}

	const registerUser = async () => {
		try {
			setIsLoading(true)
			const [registerResponse] = await Promise.all([
				auth.createOne(
					values.firstName,
					values.lastName,
					values.birthday,
					values.email,
					values.code,
					values.password,
					values.confirmPassword,
					values.token
				),
				asyncTimeout(800),
			])

			localStorage.setItem('WHMToken', registerResponse.accessToken.token)
			dispatch({ type: 'setUser', data: registerResponse.user })
			navigate('/subscribe')
		} catch (error) {
			let message
			if (error instanceof Error) message = error.message
			else message = String(error)
			dispatch({
				type: 'setSnack',
				data: {
					isOpen: true,
					severity: 'error',
					message: message,
				},
			})
		} finally {
			setIsLoading(false)
		}
	}

	useEffect(() => {
		if (!isRegisterProps(state)) {
			navigate('/login', { replace: true })
			return
		}
		setFieldValue('code', state.code)
		setFieldValue('token', state.token)
	}, [state])

	return (
		<div className='px-4 py-8 h-screen md:h-auto flex flex-col'>
			<div className='flex items-center justify-center relative mb-8 w-full'>
				<Arrow className='absolute left-0' onClick={() => navigate('/login')} />
				<img src={Logo} className='h-5' alt='WHM Logo' />
			</div>

			<div className='text-[30px] font-bold text-black-base mb-2 leading-[35px]'>Account Details</div>
			<div className='text-[18px] text-black-light leading-5 mb-6'>We just need a few details to finish creating your account</div>

			<h2 className='font-semibold mb-2'>Full Name</h2>

			<ValidationInput
				error={errors.firstName}
				touched={touched.firstName}
				value={values.firstName}
				onChange={(e) => setFieldValue('firstName', e.target.value)}
				placeholder='First Name ...'
				className='mb-2'
			/>

			<ValidationInput
				error={errors.lastName}
				touched={touched.lastName}
				value={values.lastName}
				onChange={(e) => setFieldValue('lastName', e.target.value)}
				placeholder='Last Name ...'
				className='mb-3'
			/>

			<h2 className='font-semibold mb-2'>Email Address</h2>

			<ValidationInput
				error={errors.email}
				touched={touched.email}
				value={values.email}
				onChange={(e) => setFieldValue('email', e.target.value)}
				placeholder='Your Email Address ...'
				className='mb-3'
			/>

			<h2 className='font-semibold mb-2'>Date of Birth</h2>
			<DateValidationInput
				error={errors.birthday}
				touched={touched.birthday}
				value={values.birthday}
				onChange={(newDate) => updateDOB(newDate)}
				placeholder='DD/MM/YYYY'
				className='mb-3'
			/>

			<h2 className='font-semibold mb-2'>Password</h2>
			<ValidationInput
				inputType={passwordInputType}
				error={errors.password}
				touched={touched.password}
				value={values.password}
				onChange={(e) => setFieldValue('password', e.target.value)}
				placeholder='Password ...'
				className='mb-2'
				icon={<ShowPassword onClick={() => setPasswordInputType(passwordInputType === 'password' ? 'text' : 'password')} />}
			/>

			<ValidationInput
				inputType={confirmPasswordInputType}
				error={errors.confirmPassword}
				touched={touched.confirmPassword}
				value={values.confirmPassword}
				onChange={(e) => setFieldValue('confirmPassword', e.target.value)}
				placeholder='Confirm Password ...'
				className='mb-3'
				icon={<ShowPassword onClick={() => setConfirmPasswordInputType(confirmPasswordInputType === 'password' ? 'text' : 'password')} />}
			/>

			<div className='p-2 border border-[#33333320] rounded-md'>
				<div className='text-[#333333] font-semibold'>Password Security</div>
				<div className='text-[#33333380]'>
					Create a strong password by including at least 8 characters, an upper and lower case letter and a numerical value.
				</div>
			</div>

			<Button text='Next' onClick={handleSubmit} className='mt-6 mb-12' isLoading={isLoading} />

			<div className='py-4' />
		</div>
	)
}

export default Register
